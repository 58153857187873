import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Table,
} from "react-bootstrap";
import {
  getClientEnvironment,
  getEnvironmentConfiguration,
  updateEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import UpdateRdsConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateRdsConfiguration";
import UpdateFargateConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateFargateConfiguration";
import UpdateMirthConnectConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateMirthConnectConfiguration";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";
import { convertDateListToString } from "utilities/dateUtilities";
import { DateObject } from "react-multi-date-picker";

function UpdateEnvironment() {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory();

  // Create states to keep the information of selected values
  const [clientEnvironmentData, setClientEnvironmentData] = useState({});
  const [alert, setAlert] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const [rdsConfiguration, setRdsConfiguration] = useState({});
  const [fargateConfiguration, setFargateConfiguration] = useState({});
  const [mirthConnectConfiguration, setMirthConnectConfiguration] = useState({});
  const [rdsDetailsChanged, setRdsDetailsChanged] = useState(false);
  const [fargateDetailsChanged, setFargateDetailsChanged] = useState(false);
  const [mirthConnectDetailsChanged, setMirthConnectDetailsChanged] = useState(false);
  const [rdsConfigurationChanges, setRdsConfigurationChanges] = useState({});
  const [fargateConfigurationChanges, setFargateConfigurationChanges] =
    useState({});
  const [mirthConnectConfigurationChanges, setMirthConnectConfigurationChanges] =
    useState({});
  const [updatedAt, setUpdatedAt] = useState("");

  const [showLoader, setShowLoader] = useState(true);
  // const [errorMessage, setErrorMessage] = useState(null);

  const fetchClientEnvironment = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getClientEnvironment, {
          client_id: clientId,
          client_environment_id: clientEnvironmentId,
          start_date: undefined,
          end_date: undefined,
          metric_date_list: convertDateListToString([new DateObject()]),
        })
      );
      let isAccessibleState = false;
      if (response.data?.getClientEnvironment?.success) {
        const data =
          response.data.getClientEnvironment.body?.client_environment_data;
        setClientEnvironmentData(data);
        isAccessibleState = isStatusInAccessibleState(data.deploy_status);
      } else {
        console.error(response.data?.getClientEnvironment?.message);
      }
      return response.data?.getClientEnvironment?.success && isAccessibleState;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchEnvironmentConfiguration = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getEnvironmentConfiguration, {
          clientEnvironmentId: clientEnvironmentId,
        })
      );
      if (response.data?.getEnvironmentConfiguration?.success) {
        let config = JSON.parse(
          response.data?.getEnvironmentConfiguration?.body?.response
        );
        const updated_at = config.updated_at;
        config = config.client_environment_configuration;
        setConfiguration(config);
        setUpdatedAt(updated_at);
        setRdsConfiguration(config.rds_postgres_config);
        setFargateConfiguration({
          container_cpu_units: config.container_cpu_units,
          container_memory: config.container_memory,
          desired_task_count: config.desired_task_count,
        });
        if (config.hasOwnProperty("tmp_recommended")) {
          setMirthConnectConfiguration({tmp_recommended: config.tmp_recommended});
        } else {
          notify(
            "danger", 
            "Default value Mirth Connect Configuration of true has been set. " + 
            "It can be changed anytime, but you must update this environment to apply the preferred settings."
          );
          setMirthConnectConfiguration({tmp_recommended: true});
          handleMirthConnectConfigurationChange(
            "tmp_recommended",
            true,
            "Use Recommended Mirth Platform Settings"
          );
        }
      } else {
        console.log(response.data?.getEnvironmentConfiguration?.message);
      }
      return response.data?.getEnvironmentConfiguration?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateEnvironmentConfiguration = async (environmentJson) => {
    try {
      setShowLoader(true);
      const query = {
        environment_json: JSON.stringify(environmentJson),
        previously_updated_at: updatedAt,
      };
      const response = await API.graphql(
        graphqlOperation(updateEnvironment, query)
      );
      if (response.data?.updateEnvironment?.success) {
        notify("success");
        setConfiguration(environmentJson);
        setRdsDetailsChanged(false);
        setFargateDetailsChanged(false);
        setTimeout(() => {
          history.push({
            pathname: `/admin/clients/${clientId}`,
          });
        }, 3000);
      } else {
        console.error(response.data?.updateEnvironment?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const promiseAllResults = await Promise.all([
        fetchClientEnvironment(),
        fetchEnvironmentConfiguration(),
      ]);
      const showPage = promiseAllResults.every((value) => value);
      if (!showPage) {
        notify(
          "danger",
          "This environment is being updated, redirecting back to previous page."
        );
        history.goBack();
      }
      setShowLoader(false);
    }
    fetchData();
  }, []);

  const handleRdsConfigurationChange = (key, value, label) => {
    // console.log({...rdsConfiguration, [key]: value});
    setRdsConfiguration({ ...rdsConfiguration, [key]: value });
    setRdsConfigurationChanges({
      ...rdsConfigurationChanges,
      [key]: { label, from: configuration.rds_postgres_config[key], to: value },
    });
    setRdsDetailsChanged(true);
  };

  const handleFargateConfigurationChange = (key, value, label) => {
    // console.log({ ...fargateConfiguration, [key]: value });
    if (key === "container_cpu_units") {
      setFargateConfiguration({
        ...fargateConfiguration,
        container_cpu_units: value,
        container_memory: null,
      });
    } else {
      setFargateConfiguration({ ...fargateConfiguration, [key]: value });
    }
    setFargateConfigurationChanges({
      ...fargateConfigurationChanges,
      [key]: { label, from: configuration[key], to: value },
    });
    setFargateDetailsChanged(true);
  };

  const handleMirthConnectConfigurationChange = (key, value, label) => {
    setMirthConnectConfiguration({ ...mirthConnectConfiguration, [key]: value });
    setMirthConnectConfigurationChanges({
      ...mirthConnectConfigurationChanges,
      [key]: { label, from: String(configuration[key] ? configuration[key] : ""), to: String(value) },
    });
    setMirthConnectDetailsChanged(true);
  };

  const handleRdsConfigurationReset = () => {
    setRdsConfiguration(configuration.rds_postgres_config);
    setRdsConfigurationChanges({});
    setRdsDetailsChanged(false);
  };

  const handleFargateConfigurationReset = () => {
    setFargateConfiguration({
      container_cpu_units: configuration.container_cpu_units,
      container_memory: configuration.container_memory,
      desired_task_count: configuration.desired_task_count,
    });
    setFargateConfigurationChanges({});
    setFargateDetailsChanged(false);
  };

  const handleMirthConnectConfigurationReset = () => {
    if (configuration.hasOwnProperty("tmp_recommended")) {
      setMirthConnectConfiguration({tmp_recommended: configuration.tmp_recommended});
      setMirthConnectConfigurationChanges({});
      setMirthConnectDetailsChanged(false);
    } else {
      notify(
        "danger", 
        "Default value Mirth Connect Configuration of true has been set."
      );
      setMirthConnectConfiguration({tmp_recommended: true});
      handleMirthConnectConfigurationChange(
        "tmp_recommended",
        true,
        "Use Recommended Mirth Platform Settings"
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const allocatedStorageIncreased =
      rdsConfiguration.allocated_storage -
      configuration.rds_postgres_config.allocated_storage;
    const allowedIncreaseValue = Math.round(
      configuration.rds_postgres_config.allocated_storage * 0.1
    );
    const allocatedStorageAllowed =
      allocatedStorageIncreased === 0 ||
      (allocatedStorageIncreased >= allowedIncreaseValue &&
        allocatedStorageIncreased >= 5);
    if (!fargateConfiguration.container_memory)
      notify("danger", "Please Select Container Memory");
    else if (!allocatedStorageAllowed)
      notify(
        "danger",
        `You can only increase the value of allocated storage by either 5 GB or 10% of the original value(${allowedIncreaseValue} GB), whichever is greater.`
      );
    else {
      const configurationCopy = { ...configuration };
      configurationCopy.rds_postgres_config = { ...rdsConfiguration };
      configurationCopy.container_cpu_units =
        fargateConfiguration.container_cpu_units;
      configurationCopy.container_memory =
        fargateConfiguration.container_memory;
      configurationCopy.mc_vmoptions = `-Xmx${fargateConfiguration.container_memory}m`;
      configurationCopy.desired_task_count =
        fargateConfiguration.desired_task_count;
      configurationCopy.desired_task_count_backup =
        fargateConfiguration.desired_task_count;
      configurationCopy.tmp_recommended = mirthConnectConfiguration.tmp_recommended;
      /* const detailsChanged = [];
      rdsDetailsChanged && detailsChanged.push("RDS");
      fargateDetailsChanged && detailsChanged.push("Fargate");
      let confirmText = detailsChanged[0];
      if (detailsChanged.length > 1) {
        const lastDetail = detailsChanged.pop();
        confirmText = `${detailsChanged.join(", ")} and ${lastDetail}`;
      }
      confirmText =
        confirmText + " configuration will be changed! Are you sure?"; */
      /* let confirmText = "";
      for (let key in configurationChange) {
        confirmText =
          confirmText +
          `${key}: from ${configurationChange[key].from} to ${configurationChange[key].to}` +
          "\n";
      } */
      setAlert(
        <SweetAlert
          showCancel
          title={"Configuration Changes"}
          onConfirm={() => {
            notify("info", "Creating Update Environment Job.");
            updateEnvironmentConfiguration(configurationCopy);
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          <Table className="table-hover table-striped w-full">
            <thead>
              <tr key={`configuration-change-header`}>
                <td>Name</td>
                <td>Old Value</td>
                <td>New Value</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(rdsConfigurationChanges).map((key) => {
                return (
                  <tr key={`configuration-change-${key}`}>
                    <td>{rdsConfigurationChanges[key].label}</td>
                    <td>{rdsConfigurationChanges[key].from}</td>
                    <td>{rdsConfigurationChanges[key].to}</td>
                  </tr>
                );
              })}
              {Object.keys(fargateConfigurationChanges).map((key) => {
                return (
                  <tr key={`configuration-change-${key}`}>
                    <td>{fargateConfigurationChanges[key].label}</td>
                    <td>{fargateConfigurationChanges[key].from}</td>
                    <td>{fargateConfigurationChanges[key].to}</td>
                  </tr>
                );
              })}
              {Object.keys(mirthConnectConfigurationChanges).map((key) => {
                return (
                  <tr key={`configuration-change-${key}`}>
                    <td>{mirthConnectConfigurationChanges[key].label}</td>
                    <td>{mirthConnectConfigurationChanges[key].from}</td>
                    <td>{mirthConnectConfigurationChanges[key].to}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </SweetAlert>
      );
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Update Environment</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <form onSubmit={handleSubmit}>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">
                    {clientEnvironmentData.client_environment_name} (
                    {clientEnvironmentId})
                  </Card.Title>
                </Card.Header>
                <Card.Body className="content-full-width">
                  <UpdateRdsConfiguration
                    configuration={rdsConfiguration}
                    onChange={handleRdsConfigurationChange}
                    onReset={handleRdsConfigurationReset}
                  />
                  <UpdateFargateConfiguration
                    configuration={fargateConfiguration}
                    onChange={handleFargateConfigurationChange}
                    onReset={handleFargateConfigurationReset}
                  />
                  <UpdateMirthConnectConfiguration
                    configuration={mirthConnectConfiguration}
                    onChange={handleMirthConnectConfigurationChange}
                    onReset={handleMirthConnectConfigurationReset}
                  />
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col md="12">
                      <Button
                        className="btn-fill"
                        variant="primary"
                        type="submit"
                        style={{ float: "right", backgroundColor: "#447DF7" }}
                        disabled={!rdsDetailsChanged && !fargateDetailsChanged && !mirthConnectDetailsChanged}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UpdateEnvironment;
