import { useState, useEffect } from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function UpdateMirthConnectConfiguration({ configuration, onChange, onReset }) {

  // Create states to keep the information of selected values
  const [backupConfiguration, setBackupConfiguration] = useState({});
  const [tmpRecommended, setTmpRecommended] = useState(null);

  useEffect(() => {
    setBackupConfiguration({ ...configuration });
    setTmpRecommended(configuration.tmp_recommended)
  }, []);

  const handleMirthConnectConfigurationChange = (key, value, label) => {
    onChange(key, value, label);
  };

  const reset = () => {
    onReset();
    setTmpRecommended(backupConfiguration.tmp_recommended);
  };

  return (
    <>
      <Container fluid>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Mirth Connect Configuration</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                <Form.Group>
                  <input
                    type="checkbox"
                    checked={tmpRecommended}
                    onChange={() => {
                      handleMirthConnectConfigurationChange(
                        "tmp_recommended",
                        !tmpRecommended,
                        "Use Recommended Mirth Platform Settings"
                      );
                      setTmpRecommended(!tmpRecommended);
                    }}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <label
                    style={{
                      position: "relative",
                      top: "-7px",
                      left: "5px",
                    }}
                  >
                    Use Recommended Mirth Platform Settings
                  </label>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button className="btn-fill" variant="warning" onClick={reset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default UpdateMirthConnectConfiguration;
