import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";

// react-bootstrap components
import { Button, Card, Row, Col, Spinner, Form } from "react-bootstrap";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { withAuthenticator } from "@aws-amplify/ui-react";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import { getList } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";

let spoke_client_hashmap = {};
let client_spoke_hashmap = {};
let spoke_environment_hashmap = {};
let environment_spoke_hashmap = {};
let environment_client_hashmap = {};
let client_environment_hashmap = {};
let spoke_channel_hashmap = {};
let channel_spoke_hashmap = {};
let client_channel_hashmap = {};
let channel_client_hashmap = {};
let environment_channel_hashmap = {};
let channel_environment_hashmap = {};

const default_batch_status_options = [
  { value: "ALL", label: "All" },
  { value: "PENDING", label: "Pending" },
  { value: "COMPLETED", label: "Completed" },
];
let default_spoke_options = [];
let default_client_options = [];
let default_environment_options = [];
let default_channel_options = [];

function BatchFilters({ user, fetchBatches, setCurrentOffset }) {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const location = useLocation();
  const userState = location.state;

  const groupname =
    user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const userClientId =
    user.signInUserSession.idToken.payload["custom:clientId"];
  const userClientName =
    user.signInUserSession.idToken.payload["custom:clientName"];
  const isInternalUser = groupname === "internal";

  // States
  const [selectedStartDate, setSelectedStartDate] = useState(
    userState
      ? userState.start_date
        ? moment(userState.start_date)
        : moment().startOf("day")
      : moment().startOf("day")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    userState ? (userState.end_date ? moment(userState.end_date) : moment().endOf("day")) : moment().endOf("day")
  );
  const [selectedSpokeIds, setSelectedSpokeIds] = useState(
    userState ? (userState.spoke_ids ? userState.spoke_ids : []) : []
  );
  const [selectedChannelIds, setSelectedChannelIds] = useState(
    userState ? (userState.channel_ids ? userState.channel_ids : []) : []
  );
  const [selectedClientEnvironmentIds, setSelectedClientEnvironmentIds] =
    useState(
      userState
        ? userState.environment_ids
          ? userState.environment_ids
          : []
        : []
    );
  const [selectedClientIds, setSelectedClientIds] = useState(
    userState
      ? userState.client_ids
        ? userState.client_ids
        : []
      : !isInternalUser
      ? [{ value: userClientId, label: userClientName }]
      : []
  );
  const [selectedBatchStatus, setSelectedBatchStatus] = useState(
    userState
      ? userState.event_status
        ? userState.event_status
        : { value: "ALL", label: "All" }
      : { value: "ALL", label: "All" }
  );
  const [isBatchWithErrosChecked, setIsBatchWithErrosChecked] = useState(false);

  const [clientOptions, setClientOptions] = useState([]);
  const [clientEnvironmentOptions, setClientEnvironmentOptions] = useState([]);
  const [spokeOptions, setSpokeOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const loadHashes = async () => {
    fetchList("SPOKE_CLIENT_HASH");
    fetchList("CLIENT_SPOKE_HASH");
    fetchList("SPOKE_ENVIRONMENT_HASH");
    fetchList("ENVIRONMENT_SPOKE_HASH");
    fetchList("ENVIRONMENT_CLIENT_HASH");
    fetchList("CLIENT_ENVIRONMENT_HASH");
    fetchList("SPOKE_CHANNEL_HASH");
    fetchList("CLIENT_CHANNEL_HASH");
    fetchList("ENVIRONMENT_CHANNEL_HASH");
    fetchList("CHANNEL_SPOKE_HASH");
    fetchList("CHANNEL_CLIENT_HASH");
    fetchList("CHANNEL_ENVIRONMENT_HASH");
  };

  const fetchList = async (type) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getList, {
          type: type,
          auth_client_id: userClientId,
        })
      );
      if (response.data?.getList?.success) {
        let options = response.data.getList.body
          ? response.data.getList.body
              .map((data) => {
                const option = {
                  value: data.id,
                  label: data.name,
                };
                if (type == "SPOKE_CLIENT_HASH") {
                  spoke_client_hashmap[data.id] = data.hash;
                  default_spoke_options.push(option);
                } else if (type == "CLIENT_SPOKE_HASH") {
                  client_spoke_hashmap[data.id] = data.hash;
                  default_client_options.push(option);
                } else if (type == "SPOKE_ENVIRONMENT_HASH") {
                  spoke_environment_hashmap[data.id] = data.hash;
                } else if (type == "ENVIRONMENT_SPOKE_HASH") {
                  environment_spoke_hashmap[data.id] = data.hash;
                  default_environment_options.push(option);
                } else if (type == "ENVIRONMENT_CLIENT_HASH") {
                  environment_client_hashmap[data.id] = data.hash;
                } else if (type == "CLIENT_ENVIRONMENT_HASH") {
                  client_environment_hashmap[data.id] = data.hash;
                } else if (type == "CHANNEL_SPOKE_HASH") {
                  channel_spoke_hashmap[data.id] = data.hash;
                  default_channel_options.push(option);
                } else if (type == "SPOKE_CHANNEL_HASH") {
                  spoke_channel_hashmap[data.id] = data.hash;
                } else if (type == "CHANNEL_CLIENT_HASH") {
                  channel_client_hashmap[data.id] = data.hash;
                } else if (type == "CLIENT_CHANNEL_HASH") {
                  client_channel_hashmap[data.id] = data.hash;
                } else if (type == "CHANNEL_ENVIRONMENT_HASH") {
                  channel_environment_hashmap[data.id] = data.hash;
                } else if (type == "ENVIRONMENT_CHANNEL_HASH") {
                  environment_channel_hashmap[data.id] = data.hash;
                }
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];

        if (type == "CLIENT_SPOKE_HASH") {
          setClientOptions(options);
        } else if (type == "SPOKE_CLIENT_HASH") {
          setSpokeOptions(options);
        } else if (type == "ENVIRONMENT_SPOKE_HASH") {
          setClientEnvironmentOptions(options);
        } else if (type == "CHANNEL_SPOKE_HASH") {
          setChannelOptions(options);
        }
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", "Could not fetch list - " + type);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not fetch list - " + type);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    loadHashes();
  }, []);

  useEffect(() => {
    rebaseOptionsAndSelected();
  }, [
    selectedSpokeIds,
    selectedClientIds,
    selectedClientEnvironmentIds,
    selectedChannelIds,
    spokeOptions,
    clientOptions,
    clientEnvironmentOptions,
    channelOptions,
  ]);

  const sortArray = (myArray) => {
    return myArray.sort((a, b) => a.value.localeCompare(b.value));
  };
  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  const uniqueArray = (myArray) => {
    if (!myArray || myArray.length === 0) return [];
    return myArray.filter((obj, index) => {
      if (!obj) return false;
      return index === myArray.findIndex((o) => obj.value === o.value);
    });
  };

  const rebaseOptionsAndSelected = () => {
    // Rebase the spoke options
    let allowable_spoke_client = [];
    let allowable_spoke_env = [];
    let allowable_spoke_channel = [];
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_spoke_client = allowable_spoke_client.concat(
          client_spoke_hashmap[client.value]
        );
      });
    } else {
      allowable_spoke_client = default_spoke_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_spoke_env = allowable_spoke_env.concat(
          environment_spoke_hashmap[env.value]
        );
      });
    } else {
      allowable_spoke_env = default_spoke_options;
    }
    if (selectedChannelIds.length > 0) {
      selectedChannelIds.forEach(function (channel) {
        allowable_spoke_channel = allowable_spoke_channel.concat(
          channel_spoke_hashmap[channel.value]
        );
      });
    } else {
      allowable_spoke_channel = default_spoke_options;
    }
    allowable_spoke_client = uniqueArray(allowable_spoke_client);
    allowable_spoke_env = uniqueArray(allowable_spoke_env);
    allowable_spoke_channel = uniqueArray(allowable_spoke_channel);
    let newSpokeOptions = sortArray(
      allowable_spoke_client.filter(
        (o1) =>
          allowable_spoke_env.some((o2) => o1.value === o2.value) &&
          allowable_spoke_channel.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newSpokeOptions, sortArray(spokeOptions))) {
      setSpokeOptions(newSpokeOptions);
    }

    // Rebase the client options
    let allowable_client_spoke = [];
    let allowable_client_env = [];
    let allowable_client_channel = [];
    if (selectedSpokeIds.length > 0) {
      selectedSpokeIds.forEach(function (spoke) {
        allowable_client_spoke = allowable_client_spoke.concat(
          spoke_client_hashmap[spoke.value]
        );
      });
    } else {
      allowable_client_spoke = default_client_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_client_env = allowable_client_env.concat(
          environment_client_hashmap[env.value]
        );
      });
    } else {
      allowable_client_env = default_client_options;
    }
    if (selectedChannelIds.length > 0) {
      selectedChannelIds.forEach(function (channel) {
        allowable_client_channel = allowable_client_channel.concat(
          channel_client_hashmap[channel.value]
        );
      });
    } else {
      allowable_client_channel = default_client_options;
    }
    allowable_client_spoke = uniqueArray(allowable_client_spoke);
    allowable_client_env = uniqueArray(allowable_client_env);
    allowable_client_channel = uniqueArray(allowable_client_channel);
    let newClientOptions = sortArray(
      allowable_client_spoke.filter(
        (o1) =>
          allowable_client_env.some((o2) => o1.value === o2.value) &&
          allowable_client_channel.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newClientOptions, sortArray(clientOptions))) {
      setClientOptions(newClientOptions);
    }

    // Rebase the environment options
    let allowable_env_spoke = [];
    let allowable_env_client = [];
    let allowable_env_channel = [];
    if (selectedSpokeIds.length > 0) {
      selectedSpokeIds.forEach(function (spoke) {
        allowable_env_spoke = allowable_env_spoke.concat(
          spoke_environment_hashmap[spoke.value]
        );
      });
    } else {
      allowable_env_spoke = default_environment_options;
    }
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_env_client = allowable_env_client.concat(
          client_environment_hashmap[client.value]
        );
      });
    } else {
      allowable_env_client = default_environment_options;
    }
    if (selectedChannelIds.length > 0) {
      selectedChannelIds.forEach(function (channel) {
        allowable_env_channel = allowable_env_channel.concat(
          channel_environment_hashmap[channel.value]
        );
      });
    } else {
      allowable_env_channel = default_environment_options;
    }
    allowable_env_spoke = uniqueArray(allowable_env_spoke);
    allowable_env_client = uniqueArray(allowable_env_client);
    allowable_env_channel = uniqueArray(allowable_env_channel);
    let newEnvironmentOptions = sortArray(
      allowable_env_spoke.filter(
        (o1) =>
          allowable_env_client.some((o2) => o1.value === o2.value) &&
          allowable_env_channel.some((o2) => o1.value === o2.value)
      )
    );
    if (
      !compareArrays(newEnvironmentOptions, sortArray(clientEnvironmentOptions))
    ) {
      setClientEnvironmentOptions(newEnvironmentOptions);
    }

    // Rebase the channel options
    let allowable_channel_spoke = [];
    let allowable_channel_client = [];
    let allowable_channel_env = [];
    if (selectedSpokeIds.length > 0) {
      selectedSpokeIds.forEach(function (spoke) {
        allowable_channel_spoke = allowable_channel_spoke.concat(
          spoke_channel_hashmap[spoke.value]
        );
      });
    } else {
      allowable_channel_spoke = default_channel_options;
    }
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_channel_client = allowable_channel_client.concat(
          client_channel_hashmap[client.value]
        );
      });
    } else {
      allowable_channel_client = default_channel_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_channel_env = allowable_channel_env.concat(
          environment_channel_hashmap[env.value]
        );
      });
    } else {
      allowable_channel_env = default_channel_options;
    }
    allowable_channel_spoke = uniqueArray(allowable_channel_spoke);
    allowable_channel_client = uniqueArray(allowable_channel_client);
    allowable_channel_env = uniqueArray(allowable_channel_env);
    let newChannelOptions = sortArray(
      allowable_channel_spoke.filter(
        (o1) =>
          allowable_channel_client.some((o2) => o1.value === o2.value) &&
          allowable_channel_env.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newChannelOptions, sortArray(channelOptions))) {
      setChannelOptions(newChannelOptions);
    }

    // Rebase the selected spoke
    let newSpokeSelected = selectedSpokeIds.filter(function (cItem) {
      return spokeOptions.find(function (aItem) {
        return cItem.value === aItem.value && cItem.label === aItem.label;
      });
    });
    if (
      !compareArrays(
        sortArray(newSpokeSelected),
        sortArray(selectedSpokeIds)
      ) &&
      newSpokeSelected.length > 0
    ) {
      setSelectedSpokeIds(newSpokeSelected);
    }

    // Rebase the selected client
    if (isInternalUser) {
      let newClientSelected = selectedClientIds.filter(function (cItem) {
        return clientOptions.find(function (aItem) {
          return cItem.value === aItem.value && cItem.label === aItem.label;
        });
      });
      if (
        !compareArrays(
          sortArray(newClientSelected),
          sortArray(selectedClientIds)
        ) &&
        newClientSelected.length > 0
      ) {
        setSelectedClientIds(newClientSelected);
      }
    }

    // Rebase the selected environment
    let newEnvironmentSelected = selectedClientEnvironmentIds.filter(function (
      cItem
    ) {
      return clientEnvironmentOptions.find(function (aItem) {
        return cItem.value === aItem.value && cItem.label === aItem.label;
      });
    });
    if (
      !compareArrays(
        sortArray(newEnvironmentSelected),
        sortArray(selectedClientEnvironmentIds)
      ) &&
      newEnvironmentSelected.length > 0
    ) {
      setSelectedClientEnvironmentIds(newEnvironmentSelected);
    }

    // Rebase the selected channel
    let newChannelSelected = selectedChannelIds.filter(function (cItem) {
      return channelOptions.find(function (aItem) {
        return cItem.value === aItem.value && cItem.label === aItem.label;
      });
    });
    if (
      !compareArrays(
        sortArray(newChannelSelected),
        sortArray(selectedChannelIds)
      ) &&
      newChannelSelected.length > 0
    ) {
      setSelectedChannelIds(newChannelSelected);
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>Client Filter</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="clientFilter"
                  id="clientFilter"
                  closeMenuOnSelect={false}
                  isMulti
                  value={selectedClientIds}
                  onChange={(clients) => {
                    setSelectedClientIds(clients ? clients : []);
                  }}
                  isDisabled={!isInternalUser}
                  options={clientOptions}
                  isClearable={false}
                  placeholder="None Selected"
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Spoke Filter</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="spokeFilter"
                  id="spokeFilter"
                  closeMenuOnSelect={false}
                  isMulti
                  value={selectedSpokeIds}
                  onChange={(spokes) => {
                    setSelectedSpokeIds(spokes ? spokes : []);
                  }}
                  options={spokeOptions}
                  isClearable={false}
                  placeholder="None Selected"
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Client Environment Filter</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="clientEnvironmentFilter"
                  id="clientEnvironmentFilter"
                  closeMenuOnSelect={false}
                  isMulti
                  value={selectedClientEnvironmentIds}
                  onChange={(clientEnvironments) => {
                    setSelectedClientEnvironmentIds(
                      clientEnvironments ? clientEnvironments : []
                    );
                  }}
                  options={clientEnvironmentOptions}
                  isClearable={false}
                  placeholder="None Selected"
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Channel Filter</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="channelFilter"
                  id="channelFilter"
                  closeMenuOnSelect={false}
                  isMulti
                  value={selectedChannelIds}
                  onChange={(channels) => {
                    setSelectedChannelIds(channels ? channels : []);
                  }}
                  options={channelOptions}
                  isClearable={false}
                  placeholder="None Selected"
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Start of Batch Date Range</label>
                <CustomReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Not Selected",
                    value: selectedStartDate
                      ? moment(selectedStartDate).format("MM/DD/YYYY h:mm A")
                      : "",
                  }}
                  timeFormat={true}
                  value={selectedStartDate}
                  onChange={(value) => {
                    setSelectedStartDate(value);
                  }}
                ></CustomReactDatetime>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>End of Batch Date Range</label>
                <CustomReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Not Selected",
                    value: selectedEndDate
                      ? moment(selectedEndDate).format("MM/DD/YYYY h:mm A")
                      : "",
                  }}
                  timeFormat={true}
                  value={selectedEndDate}
                  onChange={(value) => {
                    setSelectedEndDate(value);
                  }}
                ></CustomReactDatetime>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Batch Status</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="batchStatus"
                  id="batchStatus"
                  value={selectedBatchStatus}
                  onChange={(event) => {
                    setSelectedBatchStatus(event);
                  }}
                  options={default_batch_status_options}
                  placeholder="Not Selected"
                />
              </Form.Group>
            </Col>
            <Col md="3" className="align-content-md-end">
              <Form.Group>
                <input
                  type="checkbox"
                  checked={isBatchWithErrosChecked}
                  onChange={() => {
                    setIsBatchWithErrosChecked(!isBatchWithErrosChecked);
                  }}
                  style={{ width: "25px", height: "25px" }}
                />
                <label
                  style={{
                    position: "relative",
                    top: "-7px",
                    left: "5px",
                  }}
                >
                  Show only batches with errors
                </label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col md="1">
              <Button
                variant="info"
                className="btn-round"
                type="button"
                onClick={() => {
                  setSelectedBatchStatus({ value: "ALL", label: "All" });
                  setIsBatchWithErrosChecked(false);
                  setSelectedStartDate(moment().startOf("day"));
                  setSelectedEndDate(moment().endOf("day"));
                  setSelectedSpokeIds([]);
                  setSelectedClientEnvironmentIds([]);
                  setSelectedChannelIds([]);
                  setSpokeOptions(default_spoke_options);
                  setClientEnvironmentOptions(default_environment_options);
                  setChannelOptions(default_channel_options);
                  if (isInternalUser) {
                    setSelectedClientIds([]);
                    setClientOptions(default_client_options);
                  }
                }}
              >
                Reset
              </Button>
            </Col>
            <Col md="1">
              <Button
                variant="info"
                className="btn-round"
                type="button"
                onClick={() => {
                  const selected_state = {
                    start_date: selectedStartDate
                      ? selectedStartDate.format()
                      : null,
                    end_date: selectedEndDate ? selectedEndDate.format() : null,
                    batch_status: selectedBatchStatus.value,
                    spoke_ids: selectedSpokeIds.map((obj) => obj.value),
                    client_ids: selectedClientIds.map((obj) => obj.value),
                    environment_ids: selectedClientEnvironmentIds.map((obj) => obj.value),
                    channel_ids: selectedChannelIds.map((obj) => obj.value),
                    show_batch_with_errors: isBatchWithErrosChecked,
                  };
                  setCurrentOffset(0);
                  fetchBatches(selected_state);
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default withAuthenticator(BatchFilters, { hideSignUp: true });
