import React from "react";
import { useState, useContext } from "react";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Nav,
  Tab,
} from "react-bootstrap";
import {
  updateClientEnvironmentAuth,
  updateEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import ChannelSelector from "./ChannelSelector";
import { copyToClipboard } from "utilities/commonUtilities";

const copyButtonCustom = {
  position: "relative",
  top: "27px",
};

function ChannelSecurity() {
  const availableAuthenticationFields = {
    BASIC: [
      {
        key: "user_name",
        label: "User Name",
      },
      {
        key: "password",
        label: "Password",
      },
    ],
    API_KEY: [
      {
        key: "user_name",
        label: "User Name",
      },
      {
        key: "api_key",
        label: "API Key",
      },
    ],
    SFTP: [
      {
        key: "user_name",
        label: "SFTP User",
      },
      {
        key: "public_key",
        label: "SFTP Public Key",
      },
    ],
  };
  // Contexts
  const notify = useContext(NotificationAlertContext);
  // Create states to keep the information of selected values
  const [alert, setAlert] = useState(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [selectedChannels, setSelectedChannels] = useState(null);
  const [authenticationFields, setAuthenticationFields] = useState([]);
  const [authenticationValue, setAuthenticationValue] = useState({
    user_name: "",
    password: "",
    api_key: "",
    public_key: "",
  });
  const [authenticationData, setAuthenticationData] = useState({});
  const [activeKeyTab, setActiveKeyTab] = useState("addAuth");
  const [configuration, setConfiguration] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);

  const updateEnvironmentAuth = async (auth_json) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(updateClientEnvironmentAuth, {
          auth_json: JSON.stringify(auth_json),
        })
      );
      if (response.data?.updateClientEnvironmentAuth?.success) {
        notify("success");
        setAuthenticationValue({
          user_name: "",
          password: "",
          api_key: "",
          public_key: "",
        });
      } else {
        console.error(response.data?.updateClientEnvironmentAuth?.message);
        notify("danger", `Could not update client environment auth details.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not update client environment auth details.`);
    } finally {
      setSelectedUsername(null);
      setSelectedChannels(null);
      setShowLoader(false);
    }
  };

  const updateEnvironmentConfiguration = async (authObject) => {
    try {
      configuration["sftp_users"] = configuration["sftp_users"] || [];

      const usernameExists = configuration["sftp_users"].some(
        (item) => item.user_name === authObject.username
      );
      if (usernameExists) {
        notify("danger", "Username already exists as SFTP User");
        return;
      }
      configuration["sftp_users"].push({
        user_name: authenticationValue.user_name,
        channel_id: authObject.channelId,
      });

      setShowLoader(true);
      const query = {
        environment_json: JSON.stringify(configuration),
        previously_updated_at: updatedAt,
      };
      const response = await API.graphql(
        graphqlOperation(updateEnvironment, query)
      );
      if (response.data?.updateEnvironment?.success) {
        notify("success");
        setConfiguration(configuration);
      } else {
        console.error(response.data?.updateEnvironment?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setSelectedUsername(null);
      setSelectedChannels(null);
      setShowLoader(false);
    }
  };

  const handleUsernameSelectionChange = (event) => {
    setSelectedUsername(event);
    if (event) {
      setAuthenticationValue({
        user_name: event.user_name,
        password: event.password,
        api_key: event.api_key,
        public_key: event.public_key,
      });
    } else {
      setAuthenticationValue({
        user_name: "",
        password: "",
        api_key: "",
        public_key: "",
      });
    }
  };

  const handleChannelSelectionChange = (event) => {
    setSelectedChannels(event);
    if (event) {
      setAuthenticationFields(
        availableAuthenticationFields[event[0] ? event[0].type : "SFTP"]
      );
    } else if (activeKeyTab === "addAuth") {
      setAuthenticationValue({
        user_name: "",
        password: "",
        api_key: "",
        public_key: "",
      });
      setAuthenticationFields([]);
    }
  };

  const handleAuthTypeChange = (event) => {
    setAuthenticationData(event);
  };

  const checkUserNameExists = (username, authList) => {
    return authList.some((auth) => auth.user_name === username);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let authObject = {};
    if (selectedChannels[0] && selectedChannels[0].type === "API_KEY") {
      if (activeKeyTab === "addAuth") {
        if (
          checkUserNameExists(
            authenticationValue.user_name,
            authenticationData.api_key_auth
          )
        ) {
          notify(
            "danger",
            "Username already exists! Please provide a different username."
          );
          return;
        }
        authObject = {
          client_environment_id: selectedEnvironment,
          client_environment_api_key_auth: [
            ...authenticationData.api_key_auth,
            {
              api_key: authenticationValue.api_key,
              user_name: authenticationValue.user_name,
              routes: selectedChannels?.map((channel) => channel.value),
            },
          ],
          current_created_date: authenticationData.api_key_auth_created_date,
        };
      } else {
        authenticationData.api_key_auth[selectedUsername.index] = {
          api_key: authenticationValue.api_key,
          user_name: authenticationValue.user_name,
          routes: selectedChannels?.map((channel) => channel.value),
        };
        authObject = {
          client_environment_id: selectedEnvironment,
          client_environment_api_key_auth: authenticationData.api_key_auth,
          current_created_date: authenticationData.api_key_auth_created_date,
        };
      }
      updateEnvironmentAuth(authObject);
    } else if (selectedChannels[0] && selectedChannels[0].type === "BASIC") {
      if (activeKeyTab === "addAuth") {
        if (
          checkUserNameExists(
            authenticationValue.user_name,
            authenticationData.basic_auth
          )
        ) {
          notify(
            "danger",
            "Username already exists! Please provide a different username."
          );
          return;
        }
        authObject = {
          client_environment_id: selectedEnvironment,
          client_environment_basic_auth: [
            ...authenticationData.basic_auth,
            {
              password: authenticationValue.password,
              user_name: authenticationValue.user_name,
              routes: selectedChannels?.map((channel) => channel.value),
            },
          ],
          current_created_date: authenticationData.basic_auth_created_date,
        };
      } else {
        authenticationData.basic_auth[selectedUsername.index] = {
          password: authenticationValue.password,
          user_name: authenticationValue.user_name,
          routes: selectedChannels?.map((channel) => channel.value),
        };
        authObject = {
          client_environment_id: selectedEnvironment,
          client_environment_basic_auth: authenticationData.basic_auth,
          current_created_date: authenticationData.basic_auth_created_date,
        };
      }
      updateEnvironmentAuth(authObject);
    } else if (
      selectedChannels &&
      selectedChannels.type &&
      selectedChannels.type === "SFTP"
    ) {
      if (activeKeyTab === "addAuth") {
        authObject = {
          username: authenticationValue.user_name,
          channelId: selectedChannels.channelId,
        };
        updateEnvironmentConfiguration(authObject);
      } else {
        authObject = {
          client_environment_id: selectedEnvironment,
          client_environment_sftp_auth: true,
          username: authenticationValue.user_name,
          public_key: authenticationValue.public_key,
        };
        updateEnvironmentAuth(authObject);
      }
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Configure Channel Security</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <form onSubmit={handleSubmit}>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Authentication</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Tab.Container
                    id="channel-security-tabs"
                    activeKey={activeKeyTab}
                    onSelect={(key) => {
                      setActiveKeyTab(key);
                      setSelectedUsername(null);
                      setSelectedChannels(null);
                      setAuthenticationValue({
                        user_name: "",
                        password: "",
                        api_key: "",
                        public_key: "",
                      });
                    }}
                  >
                    <Nav role="tablist" variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="addAuth">
                          Add Authentication
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="updateAuth">
                          Update Authentication
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="addAuth">
                        <ChannelSelector
                          selectedChannels={selectedChannels}
                          selectedUsername={selectedUsername}
                          onEnvironmentSelectionChange={setSelectedEnvironment}
                          onUsernameSelectionChange={
                            handleUsernameSelectionChange
                          }
                          onChannelSelectionChange={
                            handleChannelSelectionChange
                          }
                          setConfiguration={setConfiguration}
                          setUpdatedAt={setUpdatedAt}
                          onAuthTypeChange={handleAuthTypeChange}
                          isUpdatingAuth={false}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="updateAuth">
                        <ChannelSelector
                          selectedChannels={selectedChannels}
                          selectedUsername={selectedUsername}
                          onEnvironmentSelectionChange={setSelectedEnvironment}
                          onUsernameSelectionChange={
                            handleUsernameSelectionChange
                          }
                          onChannelSelectionChange={
                            handleChannelSelectionChange
                          }
                          setConfiguration={setConfiguration}
                          setUpdatedAt={setUpdatedAt}
                          onAuthTypeChange={handleAuthTypeChange}
                          isUpdatingAuth={true}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                  {selectedChannels && (
                    <Container fluid>
                      <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                          <Card.Title as="h4">Authentication Form</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            {authenticationFields.map((field, index) => (
                              <Col key={`authentication-field-${index}`}>
                                <Form.Group>
                                  {field.label !== "SFTP Public Key" && (
                                    <label>{field.label}</label>
                                  )}
                                  {field.label === "SFTP Public Key" &&
                                    activeKeyTab !== "addAuth" && (
                                      <label>{field.label}</label>
                                    )}
                                  {field.label &&
                                    field.label === "SFTP Public Key" &&
                                    activeKeyTab !== "addAuth" && (
                                      <textarea
                                        id={field.key}
                                        placeholder={field.label}
                                        required
                                        className="form-control"
                                        name={field.key}
                                        value={
                                          authenticationValue[field.key] || ""
                                        }
                                        onChange={(e) =>
                                          setAuthenticationValue({
                                            ...authenticationValue,
                                            [field.key]: e.target.value,
                                          })
                                        }
                                      ></textarea>
                                    )}
                                  {field.label &&
                                    field.label !== "SFTP Public Key" && (
                                      <Form.Control
                                        id={field.key}
                                        placeholder={field.label}
                                        required
                                        disabled={
                                          activeKeyTab === "updateAuth" &&
                                          field.key === "user_name"
                                        }
                                        type="text"
                                        className="form-control"
                                        name={field.key}
                                        value={
                                          authenticationValue[field.key] || ""
                                        }
                                        onChange={(e) =>
                                          setAuthenticationValue({
                                            ...authenticationValue,
                                            [field.key]: e.target.value,
                                          })
                                        }
                                      ></Form.Control>
                                    )}
                                </Form.Group>
                              </Col>
                            ))}
                            <Col md="1">
                            {JSON.stringify(authenticationData) != '{}' && (
                              <Button
                                variant="info"
                                className="btn-outline btn-round"
                                type="button"
                                style={copyButtonCustom}
                                onClick={() => {
                                  if (
                                    selectedChannels[0] &&
                                    selectedChannels[0].type === "API_KEY"
                                  ) {
                                    copyToClipboard(
                                      authenticationValue.api_key
                                    );
                                  } else if (
                                    selectedChannels &&
                                    selectedChannels.type === "SFTP"
                                  ) {
                                    copyToClipboard(
                                      authenticationValue.public_key
                                    );
                                  } else {
                                    copyToClipboard(
                                      authenticationValue.password
                                    );
                                  }
                                }}
                              >
                                <i className="fas fa-copy"></i>
                              </Button>
                              )}
                            </Col>
                          </Row>
                          {activeKeyTab === "addAuth" &&
                            selectedChannels[0] &&
                            selectedChannels[0].type === "API_KEY" && (
                              <Row>
                                <Col md="12">
                                  <Button
                                    id="generate_key"
                                    className="btn-fill"
                                    variant="warning"
                                    onClick={() =>
                                      setAuthenticationValue({
                                        ...authenticationValue,
                                        api_key: uuidv4(),
                                      })
                                    }
                                  >
                                    Generate API Key
                                  </Button>
                                </Col>
                              </Row>
                            )}
                        </Card.Body>
                      </Card>
                    </Container>
                  )}
                </Card.Body>
                {selectedChannels && (
                  <Card.Footer>
                    <Row>
                      <Col md="12">
                        <Button
                          className="btn-fill"
                          variant="primary"
                          type="submit"
                          style={{ float: "right", backgroundColor: "#447DF7" }}
                          disabled={false}
                        >
                          {activeKeyTab === "addAuth" ? "Add" : "Update"}{" "}
                          Authentication
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                )}
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChannelSecurity;
