import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

const EULAModal = ({
  isOpen,
  readOnly,
  onAccept,
  onDecline,
  onClose,
  eulaContent,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onDecline}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader>End User License Agreement</ModalHeader>
      <ModalBody className="modal-body-scroll">
        <div dangerouslySetInnerHTML={{ __html: eulaContent }} />
      </ModalBody>
      <ModalFooter>
        {readOnly && (
          <Button color="danger" style={{ margin: "auto" }} onClick={onClose}>
            Close
          </Button>
        )}
        {!readOnly && (
          <>
            <Button color="primary" onClick={onAccept}>
              Accept
            </Button>
            <Button color="secondary" onClick={onDecline}>
              Decline
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default EULAModal;
