import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import Select from "react-select";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { getChannelLibrary } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

function ChannelLibrary(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory();

  // Create states to keep the information of selected values
  const [channelNameSearch, setChannelNameSearch] = useState("");
  const [channelDescriptionSearch, setChannelDescriptionSearch] = useState("");
  const [channelTypeSearch, setChannelTypeSearch] = useState(null);
  const [channelTagSearch, setChannelTagSearch] = useState([]);
  const [channelLibraryResult, setChannelLibraryResult] = useState([]);
  const [channelLibrarySelected, setChannelLibrarySelected] = useState("");

  const channelTypeOptions = [
    {
      value: "HTTP",
      label: "HTTP Listener",
    },
    {
      value: "TCP",
      label: "TCP/IP Listener",
    },
    {
      value: "S3",
      label: "S3",
    },
    {
      value: "SFTP",
      label: "SFTP Listener",
    },
    {
      value: "SFTP_POLL",
      label: "SFTP Poller",
    },
    {
      value: "CHAN_READ",
      label: "Channel Reader",
    },
    {
      value: "HTTP_S3",
      label: "HTTP S3 Listener",
    },
    {
      value: "JS_READ",
      label: "JavaScript Reader",
    },
    {
      value: "DB_READ",
      label: "Database Reader",
    },
    {
      value: "EMAIL_READ",
      label: "Email Reader",
    },
    {
      value: "DICOM_LIST",
      label: "DICOM Listener",
    },
    {
      value: "FHIR_LIST",
      label: "FHIR Listener",
    },
    {
      value: "HDH_LIST",
      label: "Health Data Hub Listener",
    },

    {
      value: "WS_LIST",
      label: "Web Service Listener",
    },
    {
      value: "PDQV2_LIST",
      label: "PDQ V2 Listener",
    },
    {
      value: "PDQV3_LIST",
      label: "PDQ V3 Listener",
    },
    {
      value: "PIXV2_LIST",
      label: "PIX V2 Listener",
    },
    {
      value: "PIXV3_LIST",
      label: "PIX V3 Listener",
    },
    {
      value: "XDSB_LIST",
      label: "XDS.b Listener",
    },
  ];

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const [inputValue, setInputValue] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const headers = [
    { key: "channel_library_name", label: "Channel Library Name" },
    { key: "description", label: "Channel Description" },
    { key: "mcc_channel_type", label: "Mcc Channel Type" },
    { key: "created_date", label: "Created Date" },
    { key: "modified_date", label: "Modified Date" },
    { key: "created_by", label: "Created By" },
    { key: "modified_by", label: "Modified By" },
    { key: "mc_version", label: "Mc Version" },
    { key: "tag_list", label: "Tag List" },
    { key: "count", label: "# Deployed" },
  ];
  // Pagination Starts
  const columnMapping = {
    "# Deployed": "count",
    "Channel Name": "channel_library_name",
    "Channel Description": "description",
    "MC Version": "mc_version",
    "Channel Type": "mcc_channel_type",
    "Created By": "created_by",
    "Created Date": "created_date",
    "Modified By": "modified_by",
    "Modified Date": "modified_date",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Channel Name");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    if (event.type == "blur") event["key"] = "Enter";
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (
          inputValue.trim().length === 0 ||
          channelTagSearch?.find((tag) => tag.value === inputValue)
        )
          return;
        setChannelTagSearch((prev) => {
          if (prev != null) return [...prev, createOption(inputValue)];
          return [createOption(inputValue)];
        });
        setInputValue("");
        event.preventDefault();
    }
  };

  const checkSelected = (row) => {
    if (row == channelLibrarySelected) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    props.setSelectedChannelState(null);
  }, []);

  const fetchChannelLibrary = async (variables = {}, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      notify("info", "Searching the Channel Library.");
      let payload = {};
      let channel_library_name_list = [];
      let description_list = [];
      let channel_type_list = [];
      let tag_list = [];

      if (channelNameSearch != "") {
        channel_library_name_list.push(channelNameSearch);
      }
      if (channelDescriptionSearch != "") {
        description_list.push(channelDescriptionSearch);
      }
      if (channelTypeSearch) {
        channel_type_list.push(channelTypeSearch.value);
      }

      if (channelTagSearch?.length > 0) {
        for (const tag of channelTagSearch) {
          tag_list.push(tag.value);
        }
      }

      payload.channel_library_name_list = channel_library_name_list;
      payload.description_list = description_list;
      payload.channel_type_list = channel_type_list;
      payload.tag_list = tag_list;
      variables = { ...variables, ...payload };

      const response = await API.graphql(
        graphqlOperation(getChannelLibrary, variables)
      );
      if (response.data?.getChannelLibrary?.success) {
        if (!isCSVDownload) {
          const channel_list =
            response?.data?.getChannelLibrary?.body?.channel_library_data;
          const totalCount =
            response?.data?.getChannelLibrary?.body?.total_count;
          setChannelLibraryResult(channel_list);
          setTotalCount(totalCount);
          notify("success", "Channel Library search complete.");
        } else {
          const filter = {
            "Channel Library Name List": channel_library_name_list,
            "Description List": description_list,
            "Channel Type List": channel_type_list,
            "Tag List": tag_list,
          };
          const messageDataForCSV = _.get(
            response,
            "data.getChannelLibrary.body.channel_library_data"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getChannelLibrary?.message);
        notify("danger", "Error searching the Channel Library.");
      }
      return response.data?.getChannelLibrary?.success;
    } catch (error) {
      console.error(error);
      notify("danger", "Error searching the Channel Library.");
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Search Channel Library</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label>Channel Name</label>
                      <Form.Control
                        id="channelNameFilter"
                        placeholder="Channel Name"
                        disabled={false}
                        name="channelNameFilter"
                        value={channelNameSearch}
                        onChange={(e) => {
                          setChannelNameSearch(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Channel Description</label>
                      <Form.Control
                        placeholder="Channel Description"
                        disabled={false}
                        name="channelDescriptionFilter"
                        value={channelDescriptionSearch}
                        onChange={(e) => {
                          setChannelDescriptionSearch(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Channel Type</label>
                      <Select
                        id="channelTypeFilter"
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="channelTypeFilter"
                        closeMenuOnSelect={true}
                        value={channelTypeSearch}
                        onChange={(channelTypeSelected) => {
                          setChannelTypeSearch(channelTypeSelected);
                        }}
                        options={channelTypeOptions}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Tags</label>
                      <CreatableSelect
                        id="channelTagFilter"
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(newValue) => setChannelTagSearch(newValue)}
                        onInputChange={(newValue) => setInputValue(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder=""
                        value={channelTagSearch}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                const filterObj = {
                  offSet: 0,
                  limit: currentLimit,
                };
                setCurrentOffset(0);
                fetchChannelLibrary(filterObj);
              }}
            >
              Search
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                setChannelNameSearch("");
                setChannelDescriptionSearch("");
                setChannelTypeSearch(null);
                setChannelLibraryResult([]);
                setChannelLibrarySelected("");
                props.setSelectedChannelState(null);
                setChannelTagSearch([]);
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <br></br>
        <Card>
          <Card.Body>
            <Card className="strpied-tabled-with-hover">
              <Card.Body className="table-responsive p-0">
                <ReactTable
                  data={channelLibraryResult}
                  totalWidth={2000}
                  totalCount={totalCount}
                  functionCallBack={fetchChannelLibrary}
                  setStateForPagnination={setStateForPagnination}
                  currentLimit={currentLimit}
                  currentOffset={currentOffset}
                  ascOrDescValue={ascOrDescValue}
                  sortValue={sortValue}
                  numberOfRowsData={numberOfRowsData}
                  columnMapping={columnMapping}
                  tableName="Libraries"
                  CSVFileName="Channel_Library_Export"
                  CSVHeaders={headers}
                  notify={notify}
                  columns={[
                    {
                      Header: "Select",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        return (
                          <input
                            type="radio"
                            className="radio"
                            style={{ height: "25px" }}
                            defaultChecked={checkSelected(row)}
                            onChange={() => {
                              props.setSelectedChannelState(row);
                              setChannelLibrarySelected(row);
                            }}
                          />
                        );
                      },
                      filterable: false,
                      showTooltip: false,
                      sortable: false,
                      maxWidth: 80,
                    },
                    {
                      Header: "# Deployed",
                      accessor: "count",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 110,
                    },
                    {
                      Header: "Channel Name",
                      accessor: "channel_library_name",
                      filterable: false,
                      showTooltip: true,
                      minWidth: 270,
                    },
                    {
                      Header: "Channel Description",
                      accessor: "description",
                      filterable: false,
                      showTooltip: true,
                      minWidth: 280,
                    },
                    {
                      Header: "MC Version",
                      accessor: "mc_version",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 110,
                    },
                    {
                      Header: "Tags",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        const tag_list = row.tag_list;
                        const value = tag_list.join(", ");
                        return <div style={ellipsisStyle}>{value}</div>;
                      },
                      accessor: "tags",
                      filterable: false,
                      showTooltip: true,
                      sortable: false,
                      minWidth: 250,
                    },
                    {
                      Header: "Channel Type",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        const channelTypeIndex = channelTypeOptions.findIndex(
                          (x) => x.value === row.mcc_channel_type
                        );
                        const value =
                          channelTypeIndex != -1
                            ? channelTypeOptions[channelTypeIndex].label
                            : "N/A";
                        return <div style={ellipsisStyle}>{value}</div>;
                      },
                      accessor: "channel_type",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 140,
                    },
                    {
                      Header: "Created By",
                      accessor: "created_by",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 200,
                    },
                    {
                      Header: "Created Date",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        const date = new Date(
                          row.created_date
                        ).toLocaleString();
                        return <div style={ellipsisStyle}>{date}</div>;
                      },
                      accessor: "created_date",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 180,
                    },
                    {
                      Header: "Modified By",
                      accessor: "modified_by",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 200,
                    },
                    {
                      Header: "Modified Date",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        const date = new Date(
                          row.modified_date
                        ).toLocaleString();
                        return <div style={ellipsisStyle}>{date}</div>;
                      },
                      accessor: "modified_date",
                      filterable: false,
                      showTooltip: true,
                      maxWidth: 180,
                    },
                  ]}
                />
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default ChannelLibrary;
