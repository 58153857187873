import * as React from "react";
import { useState, useRef, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";

// react-bootstrap components
import { Container } from "react-bootstrap";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  SendButton,
  InfoButton,
  Avatar,
  ConversationHeader,
  InputToolbox,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

import { promptAssistantChannelError } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

const Chat = ({
  clientId,
  clientEnvironmentId,
  channelId,
  inboundMessageIdempotencyKey,
  initialPrompt,
}) => {
  const inputRef = useRef(null);
  const [msgInputValue, setMsgInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [firstPrompt, setFirstPrompt] = React.useState(true);

  useEffect(() => {
    setMsgInputValue(initialPrompt);
  }, []);

  const sendPrompt = async (message) => {
    const history = [];
    for (let i = 0; i < messages.length; i += 2) {
      history.push({
        prompt: messages[i].message,
        generation: messages[i + 1].message,
      });
    }
    const oldMessages = [...messages];
    oldMessages.push({
      message,
      direction: "outgoing",
      sender: "Satayu",
      source: require("assets/img/blank_face.jpg"),
      position: "single",
    });
    setMessages(oldMessages);
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(promptAssistantChannelError, {
          prompt: message,
          history: JSON.stringify(history),
          client_id: clientId,
          client_environment_id: clientEnvironmentId,
          channel_id: channelId,
          inbound_message_idempotency_key: inboundMessageIdempotencyKey,
        })
      );
      if (response.data?.promptAssistantChannelError?.success) {
        oldMessages.push({
          message: response.data?.promptAssistantChannelError?.body.response,
          direction: "incoming",
          sender: "Mirth Assist",
          source: require("assets/img/MirthConnect_Logo.png"),
          position: "single",
        });
        if (firstPrompt) setFirstPrompt(false);
        if (oldMessages.length >= 10) {
          oldMessages.push({
            message:
              "You have reached the maximum number (5) of questions and responses for this session of Mirth Assist. You can reload this page to start a new session if you need to ask additional questions. Previous session context will be cleared when you reload the page.Thank you for using Mirth Assist.",
            direction: "incoming",
            sender: "Mirth Assist",
            source: require("assets/img/MirthConnect_Logo.png"),
            position: "single",
          });
        }
      } else {
        console.error(response.data?.promptAssistantChannelError?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setMessages(oldMessages);
      setShowLoader(false);
    }
  };

  const handleSend = (message) => {
    sendPrompt(message);
    setMsgInputValue("");
    inputRef.current?.focus();
  };

  return (
    <>
      <Container fluid>
        <div style={{ position: "relative", height: "70vh" }}>
          <MainContainer>
            <ChatContainer>
              <ConversationHeader>
                <Avatar
                  name="Mirth Assist"
                  src={require("assets/img/MirthConnect_Logo.png")}
                />
                <ConversationHeader.Content userName="Mirth Assist" info="AI" />
                {/* <ConversationHeader.Actions>
                  <InfoButton />
                </ConversationHeader.Actions> */}
              </ConversationHeader>
              <MessageList
                {...(showLoader
                  ? {
                      typingIndicator: (
                        <TypingIndicator content="Mirth Assist is typing" />
                      ),
                    }
                  : {})}
              >
                {messages.map((m, i) => (
                  <Message key={i} model={m}>
                    <Avatar name={m.sender} src={m.source} />
                  </Message>
                ))}
              </MessageList>
              <InputToolbox
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px dashed #d1dbe4",
                }}
              >
                <MessageInput
                  ref={inputRef}
                  disabled={messages.length >= 10 || showLoader}
                  onChange={(msg) => setMsgInputValue(msg)}
                  value={msgInputValue}
                  sendButton={false}
                  attachButton={false}
                  onSend={handleSend}
                  style={{ flexGrow: 1, borderTop: 0, flexShrink: "initial" }}
                />
                <SendButton
                  onClick={() => handleSend(msgInputValue)}
                  disabled={
                    msgInputValue.length === 0 ||
                    (firstPrompt && msgInputValue.length > 3000) ||
                    (!firstPrompt && msgInputValue.length > 1000) ||
                    showLoader
                  }
                  style={{
                    fontSize: "1.2em",
                    marginLeft: 0,
                    paddingLeft: "0.2em",
                    paddingRight: "0.2em",
                  }}
                />
                {/* <InfoButton
                  onClick={() => alert("Important message!")}
                  style={{
                    fontSize: "1.2em",
                    paddingLeft: "0.2em",
                    paddingRight: "0.2em",
                  }}
                /> */}
              </InputToolbox>
            </ChatContainer>
          </MainContainer>
        </div>
      </Container>
    </>
  );
};

export default Chat;
