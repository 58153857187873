import React from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";

// core components
import { withAuthenticator } from "@aws-amplify/ui-react";

// react-bootstrap components
import {
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

// List of report options for the dropdown
const reportOptions = [
  { value: "message-outcome-content-report", label: "Message Outcome and Content Report" },
];

function ReportList() {
  const history = useHistory();

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="3">
            <Form.Group>
              <>
                <h5 style={{ marginBottom: "3px" }}>Reports</h5>
                <Select
                    id="report"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="reportList"
                    value={"Select Report"}
                    onChange={(event) => {
                      history.push({
                        pathname: `/admin/reports/${event.value}`,
                      });
                    }}
                    options={reportOptions}
                    placeholder="Select Report"
                  />
              </>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withAuthenticator(ReportList, { hideSignUp: true });
