import { useState, useEffect, useRef, useContext } from "react";
import { getCloudWatchMetrics } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import moment from "moment";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import { withAuthenticator } from "@aws-amplify/ui-react";

// react-bootstrap components
import { Button, Card, Row, Col, CloseButton, Spinner } from "react-bootstrap";

const cloudWatchImage = {
  height: "100%",
  width: "100%",
};

const CloudwatchMetric = (props) => {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { key, metric_type, index, removeMetric, client_environment_id } =
    props;
  const timezone_name = new Date()
    .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
    .substring(4)
    .match(/\b([A-Z])/g)
    .join("");
  const now = moment();
  const timezone_offset = now.format("YYYY-MM-DDTHH:mm:ssZZ").slice(-5);
  const [end, setEnd] = useState(now);
  const twoHoursBack = moment().subtract(2, "hours");
  const [start, setStart] = useState(twoHoursBack);
  const [showLoader, setShowLoader] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);
  const [image, setImage] = useState(null);
  const [tempStart, setTempStart] = useState(twoHoursBack);
  const [tempEnd, setTempEnd] = useState(now);

  const getCloudWatchMetricsData = async (start, end) => {
    const variables = {
      client_environment_id: client_environment_id,
      metric_type: metric_type,
      start: start.format("YYYY-MM-DDTHH:mm:ssZZ"),
      end: end.format("YYYY-MM-DDTHH:mm:ssZZ"),
      timezone_name: timezone_name,
      timezone_offset: timezone_offset,
    };

    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getCloudWatchMetrics, variables)
      );
      if (response.data?.getCloudWatchMetrics?.success) {
        setImage(response.data.getCloudWatchMetrics.body.metric_widget_image);
        notify("success");
      } else {
        console.error(response.data?.getCloudWatchMetrics?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  function isValidDateRange(moment1, moment2) {
    if (moment1.diff(moment2, "seconds") < 0) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getCloudWatchMetricsData(start, end);
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      <Card className="card-stats" key={key}>
        <Card.Body>
          <CloseButton onClick={() => removeMetric(index)} />
          {image && (
            <>
              <img
                src={`data:image/png;base64, ${image}`}
                alt={`CloudWatch Image`}
                style={cloudWatchImage}
              />
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <hr></hr>
          <Row>
            <Col md="6">
              <h6 className="title">Start Datetime</h6>
              <CustomReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Time",
                  readOnly: true,
                }}
                initialValue={start}
                //onClose={(value) => setStart(value)}
                onClose={(value) => setTempStart(value)}
                timeFormat="HH:mm"
              ></CustomReactDatetime>
            </Col>
            <Col md="6">
              <h6 className="title">End Datetime</h6>
              <CustomReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "End Time",
                  readOnly: true,
                }}
                initialValue={end}
                //onClose={(value) => setEnd(value)}
                onClose={(value) => setTempEnd(value)}
                timeFormat="HH:mm"
              ></CustomReactDatetime>
            </Col>
            <Col md="6">
              <Button
                variant="info"
                className="btn-round"
                type="button"
                onClick={() => {
                  if (!isValidDateRange(tempStart, tempEnd)) {
                    notify("danger", "Invalid Date/Time range.");
                  } else {
                    setStart(tempStart);
                    setEnd(tempEnd);
                    getCloudWatchMetricsData(tempStart, tempEnd);
                  }
                }}
              >
                {" "}
                Refresh
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default withAuthenticator(CloudwatchMetric, { hideSignUp: true });