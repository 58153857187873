import React from "react";

// react-bootstrap components
import { Card, Row, Col } from "react-bootstrap";

const Stat = (props) => {
  const { title, stat, iconClassName } = props;
  return (
    <Card className="card-stats">
      <Card.Body>
        <Row>
          <Col xs="5">
            <div className="icon-big text-center icon-warning">
              <i className={iconClassName}></i>
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">{title}</p>
              <Card.Title as="h4">{stat}</Card.Title>
            </div>
          </Col>
        </Row>
      </Card.Body>
      {/* <Card.Footer>
            <hr></hr>
            <div className="stats">
              <i className="fas fa-redo mr-1"></i>
              10 seconds ago
            </div>
          </Card.Footer> */}
    </Card>
  );
};

export default Stat;
