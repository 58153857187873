import { useState, useEffect, useRef, useContext } from "react";
import { gql } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import Select from "react-select";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { withAuthenticator } from "@aws-amplify/ui-react";

import NotificationAlert from "react-notification-alert";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
  Nav,
  Tab,
} from "react-bootstrap";
import {
  getAvailablePorts,
  getEnvironmentConfiguration,
  updateEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

const buttonRemoveCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "rgb(250 65 75)",
  color: "white",
  left: "10px",
};

const buttonAddCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "#25cbef",
  color: "white",
};

function UpdateRdsConfiguration({ configuration, onChange, onReset }) {
  const allInstanceOptions = {
    m5: [
      { label: "db.m5.large(vCPU: 2, Memory: 8 GiB)", value: "db.m5.large" },
      { label: "db.m5.xlarge(vCPU: 4, Memory: 16 GiB)", value: "db.m5.xlarge" },
      {
        label: "db.m5.2xlarge(vCPU: 8, Memory: 32 GiB)",
        value: "db.m5.2xlarge",
      },
      {
        label: "db.m5.4xlarge(vCPU: 16, Memory: 64 GiB)",
        value: "db.m5.4xlarge",
      },
      {
        label: "db.m5.8xlarge(vCPU: 32, Memory: 128 GiB)",
        value: "db.m5.8xlarge",
      },
      {
        label: "db.m5.12xlarge(vCPU: 48, Memory: 192 GiB)",
        value: "db.m5.12xlarge",
      },
      {
        label: "db.m5.16xlarge(vCPU: 64, Memory: 256 GiB)",
        value: "db.m5.16xlarge",
      },
      {
        label: "db.m5.24xlarge(vCPU: 96, Memory: 384 GiB)",
        value: "db.m5.24xlarge",
      },
    ],
    t4g: [
      { label: "db.t4g.micro(vCPU: 2, Memory: 1 GiB)", value: "db.t4g.micro" },
      { label: "db.t4g.small(vCPU: 2, Memory: 2 GiB)", value: "db.t4g.small" },
      {
        label: "db.t4g.medium(vCPU: 2, Memory: 4 GiB)",
        value: "db.t4g.medium",
      },
      { label: "db.t4g.large(vCPU: 2, Memory: 8 GiB)", value: "db.t4g.large" },
      {
        label: "db.t4g.xlarge(vCPU: 4, Memory: 16 GiB)",
        value: "db.t4g.xlarge",
      },
      {
        label: "db.t4g.2xlarge(vCPU: 8, Memory: 32 GiB)",
        value: "db.t4g.2xlarge",
      },
    ],
  };

  // Create states to keep the information of selected values
  const [backupConfiguration, setBackupConfiguration] = useState({});
  const [instanceOptions, setInstanceOptions] = useState([]);
  const [instanceType, setInstanceType] = useState(null);
  const [allocatedStorage, setAllocatedStorage] = useState(null);
  const [maxAllocatedStorage, setMaxAllocatedStorage] = useState(null);

  useEffect(() => {
    setBackupConfiguration({ ...configuration });
    if (configuration?.instance_class) {
      const options = allInstanceOptions[
        configuration.instance_class.split(".")[1]
      ] || [
        {
          label: configuration.instance_class,
          value: configuration.instance_class,
        },
      ];
      setInstanceOptions(options);
      setInstanceType(
        options.find(
          (instanceOption) =>
            instanceOption.value === configuration.instance_class
        )
      );
      setAllocatedStorage(configuration.allocated_storage);
      setMaxAllocatedStorage(configuration.max_allocated_storage);
    }
  }, []);

  const handleRdsConfigurationChange = (key, value, label) => {
    onChange(key, value, label);
  };

  const reset = () => {
    onReset();
    setInstanceType(
      instanceOptions.find(
        (instanceOption) =>
          instanceOption.value === backupConfiguration.instance_class
      )
    );
    setAllocatedStorage(backupConfiguration.allocated_storage);
    setMaxAllocatedStorage(backupConfiguration.max_allocated_storage);
  };

  // if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">RDS Configuration</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="5">
                <Form.Group>
                  <label>Instance Type</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="instanceType"
                    value={instanceType}
                    required
                    onChange={(object) => {
                      setInstanceType(object);
                      handleRdsConfigurationChange(
                        "instance_class",
                        object.value,
                        "Instance Type"
                      );
                    }}
                    options={instanceOptions}
                    placeholder="Select Instance Type"
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Allocated Storage (GB)</label>
                  <Form.Control
                    placeholder="Allocated Storage"
                    type="number"
                    min={`${configuration?.allocated_storage}`}
                    max={`${maxAllocatedStorage}`}
                    required
                    value={allocatedStorage || 0}
                    onChange={(e) => {
                      const value = +e.target.value;
                      setAllocatedStorage(value);
                      handleRdsConfigurationChange(
                        "allocated_storage",
                        value,
                        "Allocated Storage (GB)"
                      );
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Maximum Allocated Storage (GB)</label>
                  <Form.Control
                    placeholder="Maximum Allocated Storage"
                    type="number"
                    min={`${configuration?.max_allocated_storage}`}
                    max="65536"
                    required
                    value={maxAllocatedStorage || 0}
                    onChange={(e) => {
                      const value = +e.target.value;
                      setMaxAllocatedStorage(value);
                      handleRdsConfigurationChange(
                        "max_allocated_storage",
                        value,
                        "Maximum Allocated Storage (GB)"
                      );
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button className="btn-fill" variant="warning" onClick={reset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default UpdateRdsConfiguration;
