import React from "react";
import { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { addClient } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";


const disabledButton = {
  backgroundColor: "#447DF7",
};
function CreateClient() {
  // Contexts
  const notify = useContext(NotificationAlertContext);  

  const [clientId, setClientId] = useState("");
  const [clientNameValue, setClientNameValue] = useState("");
  const [validated, setValidated] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  const createClient = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(addClient, {
          client_id: clientId,
          client_name: clientNameValue || clientId,     
        })
      );
      if (response.data?.addClient?.success) {
        setDisableFields(true);
        notify("success");
      } else {
        setDisableFields(false);
        console.error(response.data?.addClient?.message);
        notify("danger");
      }
    } catch (error) {
      setDisableFields(false);
      console.error(error);
      notify("danger");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      createClient();
      notify("info");
    }

    setValidated(true);
  };

  return (
    <>
      <Container fluid>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Body>
              <h3>Create Client</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Form.Group controlId="validationCustomClientId">
                      <label>MCC CLIENT Id</label>
                      <Form.Control
                        required
                        placeholder="MCC Client Id"
                        type="text"
                        disabled={disableFields}
                        value={clientId}
                        minLength="4"
                        maxLength="14"
                        pattern="^[a-z0-9\-]+$"
                        onChange={(e) => {
                          setValidated(true);
                          setClientId(e.target.value);
                        }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Minimum allowed length is 4 and maximum allowed length
                        is 14.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Space, capital letters or special characters except '-'
                        are not allowed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Client Name</label>
                      <Form.Control
                        placeholder="Client Name"
                        disabled={disableFields}
                        type="text"
                        value={clientNameValue}
                        onChange={(e) => setClientNameValue(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="primary"
                        style={disableFields ? disabledButton : {}}
                        disabled={disableFields}
                      >
                        Create Client
                      </Button>
                    </Form.Group>
                  </Col>
                  {disableFields && (
                    <Col md="12">
                      <Form.Group>
                        <Link
                          className="btn btn-round btn-info"
                          to={{
                            pathname: `/admin/clients/${clientId}/environments/create-environment`,
                          }}
                        >
                          <i className="fas fa-plus"></i> Add Environment
                        </Link>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default CreateClient;
