import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  Link,
  useLocation,
  matchPath,
} from "react-router-dom";

function CustomBreadcrumb({ routes }) {
  const location = useLocation();
  let nameArray = location.pathname.split("/");
  nameArray.shift();
  nameArray.shift();

  return (
    <div>
      <Breadcrumb>
        {nameArray.map((linkName, key) => {
          let path = nameArray.slice(0, key + 1).join("/");
          let route = routes.find((route) => {
            const match = matchPath("/" + path, {
              path: route.path,
              exact: true,
              strict: false,
            });
            return match ? true : false;
          });
          if (route && path) {
            const item = {
              href: "/admin/" + path,
              name: route.name,
            };
            if (item.href === location.pathname) {
              return (
                <Breadcrumb.Item active key={key}>
                  {item.name}
                </Breadcrumb.Item>
              );
            } else {
              return (
                <Link
                  key={key}
                  style={{ paddingRight: "0.3rem" }}
                  to={{
                    pathname: item.href,
                    state: location.state,
                  }}
                >
                  {item.name}
                  <span style={{ paddingLeft: "0.3rem", color: "#6c757d" }}>
                    /
                  </span>
                </Link>
              );
            }
          }
        })}
      </Breadcrumb>
    </div>
  );
}

export default CustomBreadcrumb;
