import { Tooltip, OverlayTrigger } from "react-bootstrap";

const ellipsisStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function CustomTooltip({ id, value, placement, children }) {
  return (
    <OverlayTrigger
      key={id}
      placement={placement || "right"}
      overlay={<Tooltip id={`tooltip-${id}`}>{value}</Tooltip>}
    >
      <span style={ellipsisStyle}>{children || value}</span>
    </OverlayTrigger>
  );
}

export default CustomTooltip;
