import React from "react";
import { useEffect, useState, useContext } from "react";
import Select from "react-select";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import {
  getList,
  addUser,
  fetchCurrentEula,
  getUser,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import SweetAlert from "react-bootstrap-sweetalert";
import EULAModal from "../EULAComponent/EULAModal";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

const sweetAlertCustom = {
  display: "block",
  marginTop: "-100px",
  fontSize: "14px",
  overflow: "auto",
  textAlign: "left",
  width: "80%",
};

function CreateUpdateUser(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const history = useHistory();
  const isUserProfile = props.location.pathname.includes("/admin/user-profile");
  const userData = props.location.state;

  const [showLoader, setShowLoader] = useState(false);
  const [userEmailAddress, setUserEmailAddress] = useState(
    isUserProfile ? null : userData?.email_id
  );
  const [userFirstName, setUserFirstName] = useState(
    isUserProfile ? null : userData?.first_name
  );
  const [userLastName, setUserLastName] = useState(
    isUserProfile ? null : userData?.last_name
  );
  const [eulaVersionId, setEulaVersionId] = useState(null);
  const [eulaContent, setEulaContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  let type = {},
    isUpdate = false,
    userIdToEdit;
  if (userData?.client_id) {
    type = { value: "external", label: "External" };
  } else {
    type = { value: "internal", label: "Internal" };
  }
  if (userData?.user_id) {
    isUpdate = true;
    userIdToEdit = userData?.user_id;
  }

  const [userType, setUserType] = useState(type);
  const [clientAffiliation, setClientAffiliation] = useState({});
  const [clientOptions, setClientOptions] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [alert, setAlert] = React.useState(null);
  const [isChecked, setIsChecked] = useState(
    userData?.notify_user ? userData?.notify_user : false
  );
  const [isDisableUserChecked, setIsDisableUserChecked] = useState(
    userData?.is_hidden ? userData?.is_hidden : false
  );

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleDisableCheckboxChange = () => {
    setIsDisableUserChecked(!isDisableUserChecked);
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const createUser = async (variables) => {
    try {
      setDisableSubmit(true);
      const response = await API.graphql(graphqlOperation(addUser, variables));
      if (response.data?.addUser?.success) {
        notify("success");
        setUserEmailAddress("");
        setUserFirstName("");
        setUserLastName("");
        setTimeout(() => {
          history.goBack();
        }, 3000);
      } else if (
        response.data?.addUser?.message.includes("UsernameExistsException")
      ) {
        console.error("Email Id Already Exists");
        notify("danger", "Email Id Already Exists");
      } else if (
        response.data?.addUser?.message.includes("UserNotFoundException")
      ) {
        console.error("Email Id Does Not Exist in Cognito User Pool");
        notify("danger", "Email Id Does Not Exist in Cognito User Pool");
      } else {
        console.error(response.data?.addUser?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setDisableSubmit(false);
      setShowLoader(false);
    }
  };

  // Fetch options for clients
  const fetchList = async (type) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(graphqlOperation(getList, { type }));
      if (response.data?.getList?.success) {
        const options = response.data.getList
          ? response.data.getList.body
              .map((client) => {
                const option = {
                  value: client.id,
                  label: client.name,
                };
                if (client.id == userData?.client_id) {
                  setClientAffiliation(option);
                }
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];
        if (type === "CLIENTS") {
          setClientOptions(options);
        }
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
    } finally {
      setShowLoader(false);
    }
  };

  const getCurrentEula = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(graphqlOperation(fetchCurrentEula));
      if (response.data?.fetchCurrentEula?.success) {
        setEulaContent(response.data.fetchCurrentEula.body.content);
        setEulaVersionId(response.data.fetchCurrentEula.body.eula_version_id);
        setIsModalOpen(true);
      } else {
        console.error(response.data?.fetchCurrentEula?.message);
        notify("danger", `Could not fetch EULA content.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch EULA content.`);
    } finally {
      setShowLoader(false);
    }
  };

  const getUserDetails = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(graphqlOperation(getUser));
      if (response.data?.getUser?.success) {
        const userDetails = response.data.getUser.body;
        setUserEmailAddress(userDetails.email_id);
        setUserFirstName(userDetails.first_name);
        setUserLastName(userDetails.last_name);
        setClientAffiliation({
          value: userDetails.client_id,
          label: userDetails.client_name,
        });
        if (userDetails.client_id) {
          setUserType({ value: "external", label: "External" });
        } else {
          setUserType({ value: "internal", label: "Internal" });
        }
      } else {
        console.error(response.data?.getUser?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    isUserProfile ? getUserDetails() : fetchList("CLIENTS");
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={sweetAlertCustom}
        title={
          userData ? "Confirm to Update the user" : "Confirm to Add the user"
        }
        confirmBtnBsStyle="info"
        confirmBtnText="Confirm"
        onCancel={() => hideAlert()}
        onConfirm={() => confirmUserAdd(event)}
        focusCancelBtn
      ></SweetAlert>
    );
  };
  const confirmUserAdd = (event) => {
    event.preventDefault();
    hideAlert();
    if (!userEmailAddress) notify("danger", "Please Enter User Email Address");
    else if (!userFirstName) notify("danger", "Please Enter User First Name");
    else if (!userLastName) notify("danger", "Please Enter User Last Name");
    else if (!userType) notify("danger", "Please Select User Type");
    else if (userType.value == "external" && !clientAffiliation.value)
      notify("danger", "Please Select Client Affiliation");
    else {
      let clientAffiliationArray = [];
      if (clientAffiliation.value && userType.value == "external") {
        clientAffiliationArray = [
          { Name: "custom:clientId", Value: clientAffiliation.value },
          { Name: "custom:clientName", Value: clientAffiliation.label },
        ];
      }
      const variables = {
        email_id: userEmailAddress,
        first_name: userFirstName,
        last_name: userLastName,
        group: userType.value,
        is_notify: isChecked,
        is_hidden: isDisableUserChecked,
        custom_attributes: JSON.stringify(clientAffiliationArray),
        is_update: isUpdate,
        user_id_to_edit: userIdToEdit,
      };
      createUser(variables);
      notify("info");
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>
              {isUserProfile
                ? "User Profile"
                : userData
                ? "Edit User"
                : "Add User"}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <label>User's Email Address</label>
                        <Form.Control
                          id="email"
                          placeholder="User's Email Address"
                          type="email"
                          disabled={isUserProfile}
                          required
                          value={userEmailAddress}
                          onChange={(e) => setUserEmailAddress(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>User's First Name</label>
                        <Form.Control
                          id="firstname"
                          placeholder="User's First Name"
                          type="text"
                          disabled={isUserProfile}
                          required
                          value={userFirstName}
                          onChange={(e) => setUserFirstName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>User's Last Name</label>
                        <Form.Control
                          id="lastname"
                          placeholder="User's Last Name"
                          type="text"
                          disabled={isUserProfile}
                          required
                          value={userLastName}
                          onChange={(e) => setUserLastName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>User Type</label>
                        <Select
                          id="type"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="userType"
                          isDisabled={isUserProfile}
                          value={userType}
                          required
                          onChange={(value) => setUserType(value)}
                          options={[
                            { value: "internal", label: "Internal" },
                            { value: "external", label: "External" },
                          ]}
                          placeholder="Select User Type"
                        />
                      </Form.Group>
                    </Col>
                    {userType && userType.value == "external" && (
                      <Col md="6">
                        <Form.Group>
                          <label>Client Affiliation</label>
                          <Select
                            id="affiliation"
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="clientAffiliation"
                            isDisabled={isUserProfile}
                            value={clientAffiliation}
                            required
                            onChange={(value) => setClientAffiliation(value)}
                            options={clientOptions}
                            placeholder="Select Client Affiliation"
                          />
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  {!isUserProfile && (
                    <>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              style={{ width: "25px", height: "25px" }}
                            />
                            <label
                              style={{
                                position: "relative",
                                top: "-7px",
                                left: "5px",
                              }}
                            >
                              Click to Enable User Notifications
                            </label>
                          </Form.Group>
                        </Col>
                      </Row>
                      {userData && (
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <input
                                type="checkbox"
                                checked={isDisableUserChecked}
                                onChange={handleDisableCheckboxChange}
                                style={{ width: "25px", height: "25px" }}
                              />
                              <label
                                style={{
                                  position: "relative",
                                  top: "-7px",
                                  left: "5px",
                                }}
                              >
                                Click to Disable User
                              </label>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Button
                              className="btn-fill"
                              type="submit"
                              variant="primary"
                              disabled={disableSubmit}
                            >
                              {userData ? "Update" : "Create"} User
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  {isUserProfile && (
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <Button
                            className="btn-fill"
                            variant="primary"
                            onClick={getCurrentEula}
                          >
                            Show EULA
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {isUserProfile && (
          <EULAModal
            isOpen={isModalOpen}
            readOnly={true}
            onClose={() => setIsModalOpen(false)}
            eulaContent={eulaContent}
          />
        )}
      </Container>
    </>
  );
}

export default withAuthenticator(CreateUpdateUser, { hideSignUp: true });
