import React from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const customeDatePickerStyle = {
  height: '40px',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '8px 12px',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    fontSize: '0.875rem',
    color: '#888888',
    bottom: '2px'
}

function CustomMultiDateSelect(props) {
  const customProps = {
    ...props
  };
  return (
    <>
      <DatePicker
        {...customProps}
        style={customeDatePickerStyle}
        multiple
        // eslint-disable-next-line react/jsx-key
        plugins={[<DatePanel />]}
      ></DatePicker>
    </>
  );
}

export default CustomMultiDateSelect;
