import { useEffect, useState, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import * as moment from "moment";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import { getConnectorDetail } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  Button,
} from "react-bootstrap";

function Channel() {
  // Hooks
  const notify = useContext(NotificationAlertContext);
  const history = useHistory();

  const { clientId, clientEnvironmentId, channelId } = useParams();

  const [startDate, setStartDate] = useState(moment().subtract(1, "day"));
  const [endDate, setEndDate] = useState(moment());
  const [connectorData, setConnectorData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);

  const fetchConnector = async (query) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getConnectorDetail, query)
      );
      if (response.data?.getConnectorDetail?.success) {
        const data = _.get(response, "data.getConnectorDetail.body");
        setConnectorData(data);
      } else {
        console.error(response.data?.getConnectorDetail?.message);
      }
      return response.data?.getConnectorDetail?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const isSuccess = await fetchConnector({ clientId, channelId, clientEnvironmentId, startDate, endDate });
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  if (!connectorData) {
    return <Spinner animation="grow" />;
  }

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {connectorData && (
                  <>
                    <Card.Title as="h4">
                      Channel Name: {connectorData.channel_name}
                    </Card.Title>
                    <Card.Title as="h4">Client Id: {clientId}</Card.Title>
                    <p id="channelId" className="card-category">
                      Channel Id: {channelId}
                    </p>
                    <p className="card-category">
                      Number of connectors:{" "}
                      {connectorData.connectors
                        ? connectorData.connectors.length
                        : 0}
                    </p>
                  </>
                )}
              </Card.Header>
              <hr></hr>
              <Row>
                <Col md="2">
                  <CustomReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Start Time",
                    }}
                    timeFormat="HH:mm"
                    value={startDate}
                    onChange={setStartDate}
                  ></CustomReactDatetime>
                </Col>
                <Col md="2">
                  <CustomReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "End Time",
                    }}
                    timeFormat="HH:mm"
                    value={endDate}
                    onChange={setEndDate}
                  ></CustomReactDatetime>
                </Col>
                <Col md="2">
                  <Button
                    variant="info"
                    className="btn-round"
                    type="button"
                    onClick={async () => {
                      const isSuccess = await fetchConnector({
                        channelId,
                        clientEnvironmentId,
                        startDate,
                        endDate,
                      });
                      if (isSuccess) notify("success");
                      else notify("danger");
                    }}
                  >
                    {" "}
                    Search
                  </Button>
                </Col>
              </Row>
              <Card.Body className="table-responsive p-0">
                {connectorData &&
                  (!connectorData.connectors ||
                    connectorData.connectors.length === 0) && (
                    <>
                      <h4 style={{ textAlign: "center" }}>
                        No Data Avaliable for the Date Range
                      </h4>
                    </>
                  )}

                {connectorData &&
                  connectorData.connectors &&
                  connectorData.connectors.length > 0 && (
                    <>
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                            <th>Connector ID</th>
                            <th>Connector Name</th>
                            <th>Filtered</th>
                            <th>Errored</th>
                            <th>Sent/Transformed</th>
                            <th>Avg Process Time (sec)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {connectorData.connectors.map((connector) => {
                            return (
                              <tr>
                                <td>{connector.connector_id}</td>
                                <td>{connector.connector_name}</td>
                                <td>{connector.filtered_total}</td>
                                <td>{connector.error_total}</td>
                                <td>{connector.sent_total}</td>
                                <td>
                                  {Math.round(
                                    10000 * connector.avg_process_time
                                  ) / 10000}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Channel;
