import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Select from "react-select";
import _ from "lodash";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import {
  getClient,
  getAvailableSpokes,
  addClientEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { convertDateListToString } from "utilities/dateUtilities";
import { DateObject } from "react-multi-date-picker";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

function CreateEnvironment() {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { clientId } = useParams();
  const history = useHistory();

  const [availableSpokes, setAvailableSpokes] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);
  const [selectedSpoke, setSelectedSpoke] = useState("");
  const [selectedMccVersion, setSelectedMccVersion] = useState("");
  const [expectedClientSize, setExpectedClientSize] = useState("");
  const [mcLicenseKeyValue, setMcLicenseKey] = useState("");
  const [mccEnvironmentNameValue, setMccEnvironmentNameValue] = useState("");
  const [clientSfIdValue, setClientSfId] = useState(clientId ? clientId : "");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [useRecommendedSettings, setUseRecommendedSettings] = useState(true);

  const handleDisableCheckboxChange = () => {
    setUseRecommendedSettings(!useRecommendedSettings);
  };

  const fetchClient = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getClient, {
          client_id: clientId,
          metric_date_list: convertDateListToString([new DateObject()]),
        })
      );
      if (response.data?.getClient?.success) {
        const data = response.data.getClient.body;
        let maxId = 0;
        if (data.client_environments_body.client_environments) {
          for (const environment of data.client_environments_body.client_environments) {
            const id = environment.client_environment_id.match(/\d+$/);
            if (id) {
              const idNumber = parseInt(id[0]);
              if (idNumber > maxId) {
                maxId = idNumber;
              }
            }
          }
        }
        setMccEnvironmentNameValue(`${clientId}-${maxId + 1}`);
      } else {
        console.error(response.data?.getClient?.message);
      }
      return response.data?.getClient?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchAvailableSpokes = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getAvailableSpokes));
      if (response.data?.getAvailableSpokes?.success) {
        const data = response.data.getAvailableSpokes.body;
        const spokes = [];
        data.map((spoke) => {
          const availableSpoke = {
            value: spoke.spoke_id,
            label: spoke.spoke_name,
          };
          spokes.push(availableSpoke);
        });
        setAvailableSpokes(spokes);
      } else {
        console.error(response.data?.getAvailableSpokes?.message);
      }
      return response.data?.getAvailableSpokes?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const createClientEnvironment = async (variables) => {
    try {
      setDisableSubmit(true);
      const response = await API.graphql(
        graphqlOperation(addClientEnvironment, variables)
      );
      if (response.data?.addClientEnvironment?.success) {
        const data = response.data.addClientEnvironment.body;
        notify("success");
        setTimeout(() => {
          history.goBack();
        }, 3000);
      } else {
        console.error(response.data?.addClientEnvironment?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setDisableSubmit(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const promiseAllResults = await Promise.all([
        fetchClient(),
        fetchAvailableSpokes(),
      ]);
      const isSuccess = promiseAllResults.every((value) => value);
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
      setShowLoader(false);
    }
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedSpoke) {
      notify("danger", "Please select a Spoke");
    } else if (!selectedMccVersion) {
      notify("danger", "Please select an ECR Version");
    } else if (!expectedClientSize) {
      notify("danger", "Please select an Expected Client Size");
    } else if (!mccEnvironmentNameValue || mccEnvironmentNameValue.trim() == "") {
      notify("danger", "Please provide an MCC Environment Name");
    } else {
      const variables = {
        client_id: clientId,
        client_environment_name: mccEnvironmentNameValue,
        spoke_id: selectedSpoke.value,
        ecr_repo_version: selectedMccVersion.value,
        expected_client_size: expectedClientSize.value,
        mc_license_key: mcLicenseKeyValue,
        tmp_recommended: useRecommendedSettings,
      };
      createClientEnvironment(variables);
      notify("info");
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Create Client Environment</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="3">
                      <Form.Group>
                        <label>Spoke</label>
                        <Select
                          id="spoke"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="selectedSpoke"
                          value={selectedSpoke}
                          required
                          onChange={(value) => setSelectedSpoke(value)}
                          options={availableSpokes}
                          placeholder="Select Spoke"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <label>ECR version</label>
                        <Select
                          id="ecr-version"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="selectedMccVersion"
                          value={selectedMccVersion}
                          required
                          onChange={(value) => setSelectedMccVersion(value)}
                          options={[
                            { value: "mcc-4.4.2-1", label: "MCC 4.4.2" },
                            { value: "mcc-4.5.0-1", label: "MCC 4.5.0" },
                          ]}
                          placeholder="Select ECR Version"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <label>Expected Client Size</label>
                        <Select
                          id="client-size"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="expectedClientSize"
                          value={expectedClientSize}
                          required
                          onChange={(value) => setExpectedClientSize(value)}
                          options={[
                            { value: "test", label: "Test" },
                            { value: "small", label: "Small" },
                            { value: "medium", label: "Medium" },
                            { value: "large", label: "Large" },
                            { value: "xlarge", label: "X-Large" },
                          ]}
                          placeholder="Select Expected Client Size"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <label>MC License key</label>
                        <Form.Control
                          id="license-key"
                          placeholder="MC License key"
                          type="text"
                          required
                          value={mcLicenseKeyValue}
                          onChange={(e) => setMcLicenseKey(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>MCC Environment Name</label>
                        <Form.Control
                          id="env-name"
                          placeholder="MCC Environment Name"
                          type="text"
                          required
                          value={mccEnvironmentNameValue}
                          minLength="4"
                          maxLength="80"
                          onChange={(e) => setMccEnvironmentNameValue(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                        Minimum allowed length is 4 and maximum allowed length
                        is 80.
                      </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>Client ID</label>
                        <Form.Control
                          placeholder="Client ID"
                          type="text"
                          disabled
                          value={clientSfIdValue}
                          onChange={(e) => setClientSfId(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <input
                          type="checkbox"
                          checked={useRecommendedSettings}
                          onChange={handleDisableCheckboxChange}
                          style={{ width: "25px", height: "25px" }}
                        />
                        <label
                          style={{
                            position: "relative",
                            top: "-7px",
                            left: "5px",
                          }}
                        >
                          Use Recommended Mirth Platform Settings
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="primary"
                          disabled={disableSubmit}
                        >
                          Create Environment
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateEnvironment;
