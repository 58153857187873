function CustomStatus({ value }) {
  let color = "var(--danger)";
  if (value != null && value < 1.5) {
    if (value < 0.5) {
      color = "var(--success)";
    } else {
      color = "var(--warning)";
    }
  }
  return <i className="fa fa-circle" aria-hidden="true" style={{ color }}></i>;
}

export default CustomStatus;
