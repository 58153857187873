import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import { withAuthenticator } from "@aws-amplify/ui-react";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import { getSupportEvents, getList } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import moment from "moment";

let spoke_client_hashmap = {};
let client_spoke_hashmap = {};
let spoke_environment_hashmap = {};
let environment_spoke_hashmap = {};
let environment_client_hashmap = {};
let client_environment_hashmap = {};

let default_event_status_options = [
  { value: "ALL", label: "All" },
  { value: "OPEN", label: "Open" },
  { value: "CLOSE", label: "Close" },
];
let default_event_type_options = [
  {
    value: "MIRTH_CONNECT_ECS_ISSUE",
    label: "ECS Issue",
  },
  {
    value: "MIRTH_CONNECT_DATABASE_ISSUE",
    label: "Database Issue",
  },
  {
    value: "MIRTH_CONNECT_SUPPORT_ISSUE",
    label: "Support Issue",
  },
  {
    value: "MIRTH_CONNECT_CHANNEL_STATUS",
    label: "Channel Status",
  },
  {
    value: "MIRTH_CHANNEL_EVALUATION",
    label: "Channel Evaluation",
  },
  {
    value: "MC_HEALTHCHECK_EVALUATION",
    label: "MC Healthcheck Evaluation",
  },
  {
    value: "MCC_DEPLOYMENT_ISSUE",
    label: "MCC Deployment Issue",
  },
];
let default_spoke_options = [];
let default_client_options = [];
let default_environment_options = [];

function SupportEvents(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const history = useHistory();
  const location = useLocation();
  const userState = location.state;
  const clientId = props?.inputProps?.client_id;
  const clientName = props?.inputProps?.client_name;
  const isClientPage = props?.inputProps?.client_id ? true : false;

  const userClientId =
    props.user.signInUserSession.idToken.payload["custom:clientId"];

  // States
  const [tableData, setTableData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    userState
      ? userState.start_date
        ? moment(userState.start_date)
        : null
      : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    userState ? (userState.end_date ? moment(userState.end_date) : null) : null
  );
  const [selectedSpokeIds, setSelectedSpokeIds] = useState(
    userState ? (userState.spoke_ids ? userState.spoke_ids : []) : []
  );
  const [selectedClientEnvironmentIds, setSelectedClientEnvironmentIds] =
    useState(
      userState
        ? userState.environment_ids
          ? userState.environment_ids
          : []
        : []
    );
  const [selectedClientIds, setSelectedClientIds] = useState(
    userState
      ? userState.client_ids
        ? userState.client_ids
        : []
      : isClientPage
      ? [{ value: clientId, label: clientName }]
      : []
  );
  const [selectedEventTypes, setSelectedEventTypes] = useState(
    userState ? (userState.event_types ? userState.event_types : []) : []
  );
  const [selectedEventStatus, setSelectedEventStatus] = useState(
    userState
      ? userState.event_status
        ? userState.event_status
        : { value: "OPEN", label: "Open" }
      : { value: "OPEN", label: "Open" }
  );

  const [clientOptions, setClientOptions] = useState([]);
  const [clientEnvironmentOptions, setClientEnvironmentOptions] = useState([]);
  const [spokeOptions, setSpokeOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedState, setSelectedState] = useState(
    userState ? userState : {}
  );

  const friendlyEventTypeNames = {
    MIRTH_CONNECT_ECS_ISSUE: "ECS Issue",
    MIRTH_CONNECT_DATABASE_ISSUE: "Database Issue",
    MIRTH_CONNECT_SUPPORT_ISSUE: "Support Issue",
    MIRTH_CONNECT_CHANNEL_STATUS: "Channel Status",
    MIRTH_CHANNEL_EVALUATION: "Channel Evaluation",
    MC_HEALTHCHECK_EVALUATION: "MC Healthcheck Evaluation",
    MCC_DEPLOYMENT_ISSUE: "MCC Deployment Issue",
  };

  const extendEventType = {
    MIRTH_CHANNEL_EVALUATION: ["MIRTH_CHANNEL_EVALUATION_NO_ALERT"],
  };

  const headers = [
    { key: "event_label", label: "Event Label" },
    { key: "spoke_name", label: "Spoke Name" },
    { key: "client_name", label: "Client Name" },
    { key: "client_environment_name", label: "Client Environment Name" },
    { key: "channel_name", label: "Channel Name" },
    { key: "event_type", label: "Event Type" },
    { key: "event_start", label: "Event Start" },
    { key: "event_end", label: "Event End" },
  ];

  const loadHashes = async () => {
    fetchList("SPOKE_CLIENT_HASH");
    fetchList("CLIENT_SPOKE_HASH");
    fetchList("SPOKE_ENVIRONMENT_HASH");
    fetchList("ENVIRONMENT_SPOKE_HASH");
    fetchList("ENVIRONMENT_CLIENT_HASH");
    fetchList("CLIENT_ENVIRONMENT_HASH");
  };

  // Pagination Starts
  const columnMapping = {
    Label: "event_label",
    Spoke: "spoke_name",
    Client: "client_name",
    "Client Environment": "client_environment_name",
    Channel: "channel_name",
    "Event Type": "event_type",
    Started: "event_start",
    "User Closed": "event_end",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Started");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchList = async (type) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getList, {
          type: type,
          auth_client_id: userClientId,
        })
      );
      if (response.data?.getList?.success) {
        let options = response.data.getList.body
          ? response.data.getList.body
              .map((data) => {
                const option = {
                  value: data.id,
                  label: data.name,
                };
                if (type == "SPOKE_CLIENT_HASH") {
                  spoke_client_hashmap[data.id] = data.hash;
                  default_spoke_options.push(option);
                } else if (type == "CLIENT_SPOKE_HASH") {
                  client_spoke_hashmap[data.id] = data.hash;
                  default_client_options.push(option);
                } else if (type == "SPOKE_ENVIRONMENT_HASH") {
                  spoke_environment_hashmap[data.id] = data.hash;
                } else if (type == "ENVIRONMENT_SPOKE_HASH") {
                  environment_spoke_hashmap[data.id] = data.hash;
                  default_environment_options.push(option);
                } else if (type == "ENVIRONMENT_CLIENT_HASH") {
                  environment_client_hashmap[data.id] = data.hash;
                } else if (type == "CLIENT_ENVIRONMENT_HASH") {
                  client_environment_hashmap[data.id] = data.hash;
                }
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];

        if (type == "CLIENT_SPOKE_HASH") {
          setClientOptions(options);
        } else if (type == "SPOKE_CLIENT_HASH") {
          setSpokeOptions(options);
        } else if (type == "ENVIRONMENT_SPOKE_HASH") {
          setClientEnvironmentOptions(options);
        }
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", "Could not fetch list - " + type);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not fetch list - " + type);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchSupportEvents = async (variables = {}, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      const params = {
        start_date: selectedStartDate ? selectedStartDate.format() : null,
        end_date: selectedEndDate ? selectedEndDate.format() : null,
        event_status: selectedEventStatus ? selectedEventStatus.value : "OPEN",
        spoke_ids: selectedSpokeIds
          ? selectedSpokeIds.map((spoke) => spoke.value)
          : [],
        client_ids: selectedClientIds
          ? selectedClientIds.map((client) => client.value)
          : [],
        environment_ids: selectedClientEnvironmentIds
          ? selectedClientEnvironmentIds.map((environment) => environment.value)
          : [],
        event_types: selectedEventTypes
          ? selectedEventTypes
              .map((eventType) => {
                if (extendEventType.hasOwnProperty(eventType.value)) {
                  let ext_list = extendEventType[eventType.value];
                  ext_list.push(eventType.value);
                  return ext_list;
                } else {
                  return eventType.value;
                }
              })
              .flat()
          : [],
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(
        graphqlOperation(getSupportEvents, variables)
      );
      if (response.data?.getSupportEvents?.success) {
        if (!isCSVDownload) {
          const supportEventList =
            response.data.getSupportEvents.body.support_event_data;
          const totalCount = response.data.getSupportEvents.body.total_count;
          setTableData(supportEventList);
          setTotalCount(totalCount);
          notify("success");
        } else {
          const filter = {
            "Start Date": params.start_date,
            "End Date": params.end_date,
            "Event Status": params.event_status,
            "Spoke Ids": params.spoke_ids,
            "Client Ids": params.client_ids,
            "Environment Ids": params.environment_ids,
            "Event Types": params.event_types,
          };
          const messageDataForCSV = _.get(
            response,
            "data.getSupportEvents.body.support_event_data"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getSupportEvents?.message);
        notify("danger");
      }
      return response.data?.getSupportEvents?.success;
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      loadHashes();
      if (userState) {
        const filterObj = {
          offSet: 0,
          limit: currentLimit,
        };
        const isSuccess = await fetchSupportEvents(filterObj);
        if (!isSuccess) {
          notify("danger");
          history.goBack();
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    rebaseOptionsAndSelected();
  }, [
    selectedSpokeIds,
    selectedClientIds,
    selectedClientEnvironmentIds,
    spokeOptions,
    clientOptions,
    clientEnvironmentOptions,
  ]);

  const sortArray = (myArray) => {
    return myArray.sort((a, b) => a.value.localeCompare(b.value));
  };
  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  const uniqueArray = (myArray) => {
    if (!myArray || myArray.length === 0) return [];
    return myArray.filter((obj, index) => {
      if (!obj) return false;
      return index === myArray.findIndex((o) => obj.value === o.value);
    });
  };

  const rebaseOptionsAndSelected = () => {
    // Rebase the spoke options
    let allowable_spoke_client = [];
    let allowable_spoke_env = [];
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_spoke_client = allowable_spoke_client.concat(
          client_spoke_hashmap[client.value]
        );
      });
    } else {
      allowable_spoke_client = default_spoke_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_spoke_env = allowable_spoke_env.concat(
          environment_spoke_hashmap[env.value]
        );
      });
    } else {
      allowable_spoke_env = default_spoke_options;
    }
    allowable_spoke_client = uniqueArray(allowable_spoke_client);
    allowable_spoke_env = uniqueArray(allowable_spoke_env);
    let newSpokeOptions = sortArray(
      allowable_spoke_client.filter((o1) =>
        allowable_spoke_env.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newSpokeOptions, sortArray(spokeOptions))) {
      setSpokeOptions(newSpokeOptions);
    }

    // Rebase the client options
    let allowable_client_spoke = [];
    let allowable_client_env = [];
    if (selectedSpokeIds.length > 0) {
      selectedSpokeIds.forEach(function (spoke) {
        allowable_client_spoke = allowable_client_spoke.concat(
          spoke_client_hashmap[spoke.value]
        );
      });
    } else {
      allowable_client_spoke = default_client_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_client_env = allowable_client_env.concat(
          environment_client_hashmap[env.value]
        );
      });
    } else {
      allowable_client_env = default_client_options;
    }
    allowable_client_spoke = uniqueArray(allowable_client_spoke);
    allowable_client_env = uniqueArray(allowable_client_env);
    let newClientOptions = sortArray(
      allowable_client_spoke.filter((o1) =>
        allowable_client_env.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newClientOptions, sortArray(clientOptions))) {
      setClientOptions(newClientOptions);
    }

    // Rebase the environment options
    let allowable_env_spoke = [];
    let allowable_env_client = [];
    if (selectedSpokeIds.length > 0) {
      selectedSpokeIds.forEach(function (spoke) {
        allowable_env_spoke = allowable_env_spoke.concat(
          spoke_environment_hashmap[spoke.value]
        );
      });
    } else {
      allowable_env_spoke = default_environment_options;
    }
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_env_client = allowable_env_client.concat(
          client_environment_hashmap[client.value]
        );
      });
    } else {
      allowable_env_client = default_environment_options;
    }
    allowable_env_spoke = uniqueArray(allowable_env_spoke);
    allowable_env_client = uniqueArray(allowable_env_client);
    let newEnvironmentOptions = sortArray(
      allowable_env_spoke.filter((o1) =>
        allowable_env_client.some((o2) => o1.value === o2.value)
      )
    );
    if (
      !compareArrays(newEnvironmentOptions, sortArray(clientEnvironmentOptions))
    ) {
      setClientEnvironmentOptions(newEnvironmentOptions);
    }

    // Rebase the selected spoke
    let newSpokeSelected = selectedSpokeIds.filter(function (cItem) {
      return spokeOptions.find(function (aItem) {
        return cItem.value === aItem.value && cItem.label === aItem.label;
      });
    });
    if (
      !compareArrays(
        sortArray(newSpokeSelected),
        sortArray(selectedSpokeIds)
      ) &&
      newSpokeSelected.length > 0
    ) {
      setSelectedSpokeIds(newSpokeSelected);
    }

    // Rebase the selected client
    if (!isClientPage) {
      let newClientSelected = selectedClientIds.filter(function (cItem) {
        return clientOptions.find(function (aItem) {
          return cItem.value === aItem.value && cItem.label === aItem.label;
        });
      });
      if (
        !compareArrays(
          sortArray(newClientSelected),
          sortArray(selectedClientIds)
        ) &&
        newClientSelected.length > 0
      ) {
        setSelectedClientIds(newClientSelected);
      }
    }

    // Rebase the selected environment
    let newEnvironmentSelected = selectedClientEnvironmentIds.filter(function (
      cItem
    ) {
      return clientEnvironmentOptions.find(function (aItem) {
        return cItem.value === aItem.value && cItem.label === aItem.label;
      });
    });
    if (
      !compareArrays(
        sortArray(newEnvironmentSelected),
        sortArray(selectedClientEnvironmentIds)
      ) &&
      newEnvironmentSelected.length > 0
    ) {
      setSelectedClientEnvironmentIds(newEnvironmentSelected);
    }
  };

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const transformDates = (str) => {
    const regex =
      /(?:\d{4}-\d{2}-\d{2})(?:[ T]\d{2}:\d{2}:\d{2})(?:[.,]\d{1,6})?(?:[+]\d{2}:?(?:\d{2})?)?/gm;
    const dates = str.match(regex) || [];
    for (const date of dates) {
      str = str.replaceAll(date, new Date(date).toLocaleString());
    }
    return str;
  };

  if (showLoader) return <Spinner animation="grow" />;

  if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Support Events</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="4">
                    <Form.Group>
                      <label>Start Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedStartDate
                            ? moment(selectedStartDate).format(
                                "MM/DD/YYYY h:mm A"
                              )
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedStartDate}
                        onChange={(value) => {
                          setSelectedStartDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>End Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedEndDate
                            ? moment(selectedEndDate).format(
                                "MM/DD/YYYY h:mm A"
                              )
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedEndDate}
                        onChange={(value) => {
                          setSelectedEndDate(value);
                          setSelectedEventStatus({
                            value: "CLOSE",
                            label: "Close",
                          });
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Event Status</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventStatus"
                        value={selectedEventStatus}
                        onChange={(event) => {
                          setSelectedEventStatus(event);
                          if (event.value == "OPEN") {
                            setSelectedEndDate(null);
                          }
                        }}
                        options={default_event_status_options}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Event Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventTypeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedEventTypes}
                        onChange={(eventTypes) => {
                          setSelectedEventTypes(eventTypes);
                        }}
                        options={default_event_type_options}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Spoke Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="spokeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedSpokeIds}
                        onChange={(spokes) => {
                          setSelectedSpokeIds(spokes ? spokes : []);
                        }}
                        options={spokeOptions}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Client Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="clientFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedClientIds}
                        onChange={(clients) => {
                          setSelectedClientIds(clients ? clients : []);
                        }}
                        isDisabled={isClientPage}
                        options={clientOptions}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Client Environment Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="clientEnvironmentFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedClientEnvironmentIds}
                        onChange={(clientEnvironments) => {
                          setSelectedClientEnvironmentIds(
                            clientEnvironments ? clientEnvironments : []
                          );
                        }}
                        options={clientEnvironmentOptions}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                const selected_state = {
                  start_date: selectedStartDate
                    ? selectedStartDate.format()
                    : null,
                  end_date: selectedEndDate ? selectedEndDate.format() : null,
                  event_status: selectedEventStatus,
                  spoke_ids: selectedSpokeIds,
                  client_ids: selectedClientIds,
                  environment_ids: selectedClientEnvironmentIds,
                  event_types: selectedEventTypes,
                  offSet: 0,
                  limit: currentLimit,
                  sortType: ascOrDescValue,
                  sortFilter: columnMapping[sortValue],
                };
                setSelectedState(selected_state);
                setCurrentOffset(0);
                fetchSupportEvents(selected_state);
              }}
            >
              Search
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                setSelectedEventStatus({ value: "OPEN", label: "Open" });
                setSelectedStartDate(null);
                setSelectedEndDate(null);
                setSelectedSpokeIds([]);
                setSelectedClientEnvironmentIds([]);
                setSelectedEventTypes([]);
                setSpokeOptions(default_spoke_options);
                setClientEnvironmentOptions(default_environment_options);
                if (!isClientPage) {
                  setSelectedClientIds([]);
                  setClientOptions(default_client_options);
                }
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={tableData}
                      totalCount={totalCount}
                      functionCallBack={fetchSupportEvents}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      tableName="Support Events"
                      CSVHeaders={headers}
                      CSVFileName="Support_Events_Export"
                      notify={notify}
                      columns={[
                        {
                          Header: "Label",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            const label = transformDates(row.event_label);
                            return (
                              <Link
                                to={{
                                  pathname: `/admin/support-events/${row.client_environment_id}/${row.support_event_idempotency_key}/${row.event_type}`,
                                  state: {
                                    client_id: clientId,
                                    ...selectedState,
                                  },
                                }}
                              >
                                {label.slice(0, 30)}
                              </Link>
                            );
                          },
                          accessor: "event_label",
                          filterable: false,
                          minWidth: 200,
                          showTooltip: true,
                          placement: "right",
                        },
                        {
                          Header: "Spoke",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (!row.spoke_id && !row.spoke_name) return;
                            return (
                              <div style={ellipsisStyle}>{row.spoke_name}</div>
                            );
                          },
                          accessor: "spoke_name",
                          filterable: false,
                          showTooltip: true,
                          placement: "right",
                        },
                        {
                          Header: "Client",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (!row.client_id && !row.client_name) return;
                            return (
                              <Link
                                to={{
                                  pathname: `/admin/clients/${row.client_id}`,
                                }}
                              >
                                {row.client_name}
                              </Link>
                            );
                          },
                          accessor: "client_name",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Client Environment",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (
                              !row.client_environment_id &&
                              !row.client_environment_name
                            )
                              return;
                            return (
                              <Link
                                to={{
                                  pathname: `/admin/clients/${row.client_id}/environments/${row.client_environment_id}`,
                                }}
                              >
                                <div style={ellipsisStyle}>
                                  {row.client_environment_name}
                                </div>
                              </Link>
                            );
                          },
                          accessor: "client_environment_id",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Channel",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (!row.channel_id && !row.channel_name) return;
                            return (
                              <Link
                                to={{
                                  pathname: `/admin/clients/${row.client_id}/environments/${row.client_environment_id}/channels/${row.channel_id}`,
                                }}
                              >
                                <div style={ellipsisStyle}>
                                  {row.channel_name}
                                </div>
                              </Link>
                            );
                          },
                          accessor: "channel_name",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Event Type",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            const value =
                              friendlyEventTypeNames[row.event_type] ||
                              row.event_type;
                            return <div style={ellipsisStyle}>{value}</div>;
                          },
                          accessor: "event_type",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Started",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            const date = new Date(
                              row.event_start
                            ).toLocaleString();
                            return <div style={ellipsisStyle}>{date}</div>;
                          },
                          accessor: "event_start",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "User Closed",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (!row.event_end) return;
                            const date = new Date(
                              row.event_end
                            ).toLocaleString();
                            return <div style={ellipsisStyle}>{date}</div>;
                          },
                          accessor: "event_end",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withAuthenticator(SupportEvents, { hideSignUp: true });
