import React, { useRef, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Header } from "components/Login/Header";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import EULA from "views/EULAComponent/EULA";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";

// dynamically create dashboard routes
import routes from "routes.js";

import image3 from "assets/img/full-screen-image-3.jpg";

function Admin({ signOut, user }) {
  const location = useLocation();
  const [sidebarImage, setSidebarImage] = useState(image3);
  const [sidebarBackground, setSidebarBackground] = useState("black");

  const groupname =
    user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const userClientId =
    user.signInUserSession.idToken.payload["custom:clientId"];

  const routesForGroup = routes[groupname];

  const notificationAlertRef = useRef(null);
  const notify = (type, message = "", autoDismiss = 7) => {
    if (!message) {
      switch (type) {
        case "success":
          message = "Your request has been completed.";
          break;
        case "danger":
          message = "Unable to process your request at this time.";
          break;
        case "info":
          message = "Your request will be processed.";
          break;
      }
    }
    const options = {
      place: "br",
      message,
      type,
      icon: "nc-icon nc-bell-55",
    };
    if (autoDismiss >= 0) {
      options["autoDismiss"] = autoDismiss;
    }
    notificationAlertRef.current.notificationAlert(options);
  };

  const checkRoute = (routes, groupname, clientId) => {
    const defaultRedirectPathInternal = `/admin/dashboard`;
    const defaultRedirectPathExternal = `/admin/clients/${clientId}`;
    let match;
    for (const route of routes) {
      match = matchPath(location.pathname, {
        path: route.layout + route.path,
        exact: true,
        strict: false,
      });
      if (match) break;
    }
    if (!match) {
      if (groupname === "internal") {
        return <Redirect to={defaultRedirectPathInternal} />;
      } else if (groupname === "external") {
        return <Redirect to={defaultRedirectPathExternal} />;
      }
    } else if (
      groupname === "external" &&
      match.params.clientId &&
      match.params.clientId !== clientId
    ) {
      return <Redirect to={defaultRedirectPathExternal} />;
    }
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <EULA user={user} signOut={signOut}>
        <NotificationAlertContext.Provider value={notify}>
          {window.location.hostname.includes(
            ".devhub.mcc-iaas.nextgenaws.net"
          ) && (
            <a
              href="https://ehcg6jchrj.execute-api.us-east-2.amazonaws.com/ProdStage"
              rel="nofollow"
              style={{ display: "none" }}
              aria-hidden="true"
            >
              honeypot link
            </a>
          )}
          <div className="rna-container">
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          {checkRoute(routesForGroup, groupname, userClientId)}
          <div className="wrapper">
            <Sidebar
              routes={routesForGroup}
              image={sidebarImage}
              background={sidebarBackground}
              user={user}
            />
            <div className="main-panel">
              <AdminNavbar
                signOut={signOut}
                routes={routesForGroup}
                user={user}
              />
              <div className="content">
                <Switch>{getRoutes(routesForGroup)}</Switch>
              </div>

              {/* <AdminFooter /> */}
              <div
                className="close-layer"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              />
            </div>
          </div>
        </NotificationAlertContext.Provider>
      </EULA>
    </>
  );
}

export default withAuthenticator(Admin, {
  hideSignUp: true,
  components: {
    SignIn: {
      Header,
    },
    SetupTOTP: {
      Header,
    },
    ConfirmSignIn: {
      Header,
    },
    ResetPassword: {
      Header,
    },
    ConfirmResetPassword: {
      Header,
    },
  },
});
