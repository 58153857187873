import { useState, useEffect, useRef, useContext } from "react";
import { gql } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import Select from "react-select";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { withAuthenticator } from "@aws-amplify/ui-react";

import NotificationAlert from "react-notification-alert";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
  Nav,
  Tab,
} from "react-bootstrap";
import {
  getAvailablePorts,
  getEnvironmentConfiguration,
  updateEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

const buttonRemoveCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "rgb(250 65 75)",
  color: "white",
  left: "10px",
};

const buttonAddCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "#25cbef",
  color: "white",
};

const MIN_TASK_COUNT = 2;
const MAX_TASK_COUNT = 8;

function UpdateFargateConfiguration({ configuration, onChange, onReset }) {
  // Contexts

  const desiredTaskCountOptions = [{
    value: 0,
    label: 0,
  }];
  for (let i = MIN_TASK_COUNT; i <= MAX_TASK_COUNT; i++) {
    const taskCount = {
      value: i,
      label: i,
    };
    desiredTaskCountOptions.push(taskCount);
  }
  const cpuUnitOptions = [
    { label: "0.5 vCPU", value: 512, min: 1, max: 4, increment: 1 },
    { label: "1 vCPU", value: 1024, min: 2, max: 8, increment: 1 },
    { label: "2 vCPU", value: 2048, min: 4, max: 16, increment: 1 },
    { label: "4 vCPU", value: 4096, min: 8, max: 30, increment: 1 },
    { label: "8 vCPU", value: 8192, min: 16, max: 60, increment: 4 },
    { label: "16 vCPU", value: 16384, min: 32, max: 120, increment: 8 },
  ];

  // Create states to keep the information of selected values
  const [backupConfiguration, setBackupConfiguration] = useState({});
  const [containerCpuUnits, setContainerCpuUnits] = useState(null);
  const [containerMemory, setContainerMemory] = useState(null);
  const [desiredTaskCount, setDesiredTaskCount] = useState(null);

  function getMemoryOptions(containerCpuUnitsValue) {
    if (!containerCpuUnitsValue) return [];
    let list = [];
    for (
      let i = containerCpuUnitsValue.min;
      i <= containerCpuUnitsValue.max;
      i += containerCpuUnitsValue.increment
    ) {
      list.push({ label: i, value: i * 1024 });
    }
    return list;
  }

  useEffect(() => {
    setBackupConfiguration({ ...configuration });
    if (configuration) {
      const containerCpuUnitsValue = cpuUnitOptions.find(
        (cpuUnitOption) =>
          cpuUnitOption.value === +configuration.container_cpu_units
      );
      setContainerCpuUnits(containerCpuUnitsValue);
      setContainerMemory(
        getMemoryOptions(containerCpuUnitsValue).find(
          (memoryOption) =>
            memoryOption.value === +configuration.container_memory
        )
      );
      setDesiredTaskCount({
        value: configuration.desired_task_count,
        label: configuration.desired_task_count,
      });
    }
  }, []);

  const handleFargateConfigurationChange = (key, value, label) => {
    onChange(key, value, label);
  };

  const reset = () => {
    onReset();
    const containerCpuUnitsValue = cpuUnitOptions.find(
      (cpuUnitOption) =>
        cpuUnitOption.value === +backupConfiguration.container_cpu_units
    );
    setContainerCpuUnits(containerCpuUnitsValue);
    setContainerMemory(
      getMemoryOptions(containerCpuUnitsValue).find(
        (memoryOption) =>
          memoryOption.value === +backupConfiguration.container_memory
      )
    );
    setDesiredTaskCount({
      value: backupConfiguration.desired_task_count,
      label: backupConfiguration.desired_task_count,
    });
  };

  // if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Fargate Configuration</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>Container CPU Units</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="containerCpuUnits"
                    value={containerCpuUnits}
                    required
                    onChange={(object) => {
                      setContainerMemory(null);
                      setContainerCpuUnits(object);
                      handleFargateConfigurationChange(
                        "container_cpu_units",
                        object.value,
                        "Container CPU Units (Bytes)"
                      );
                    }}
                    options={cpuUnitOptions}
                    placeholder="Container CPU Units"
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Container Memory (GB)</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="containerMemory"
                    value={containerMemory}
                    required
                    onChange={(object) => {
                      setContainerMemory(object);
                      handleFargateConfigurationChange(
                        "container_memory",
                        object.value,
                        "Container Memory (Bytes)"
                      );
                    }}
                    options={getMemoryOptions(containerCpuUnits)}
                    placeholder="Container Memory"
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Desired Task Count</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="desiredTaskCount"
                    value={desiredTaskCount}
                    required
                    onChange={(object) => {
                      setDesiredTaskCount(object);
                      handleFargateConfigurationChange(
                        "desired_task_count",
                        object.value,
                        "Desired Task Count"
                      );
                    }}
                    options={desiredTaskCountOptions}
                    placeholder="Desired Task Count"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button className="btn-fill" variant="warning" onClick={reset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default UpdateFargateConfiguration;
